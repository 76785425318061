@media (max-width: 1024px) {
    #chartWrap{
        overflow: revert !important;
    }
    #chartFluid{
        min-width: 364px !important;
    }
  }
 
  @media (max-width: 1080px) {
    #chartWrap{
        overflow: revert !important;
    }
    #chartFluid{
        min-width: 364px !important;
    }
  }
  @media (max-width: 1024px) {
    #chartWrap{
        overflow: revert !important;
    }
    #chartFluid{
        min-width: 335px  !important;
    }
  }
  @media (max-width: 1100px) {
    #chartWrap{
        overflow: revert !important;
    }
    #chartFluid{
        min-width: 364px !important;
    }
  }
  @media (max-width: 1200px) {
    #chartWrap{
        overflow: revert !important;
    }
    #chartFluid{
        /* min-width: 364px !important; */
        margin-top: -20% !important;
        min-width: 340px !important;
    }
  }
  @media (max-width: 320px){
    #chartFluid{
        
        margin-top: -5% !important;
        min-width: 350px !important;
        margin-left: -18% !important;
        height: 334px !important;
    }
}
    @media (min-width: 375px) and (max-width: 375px){
        #chartFluid{
            margin-top: -1% !important;
            margin-left: -12% !important;
            min-width: 350px !important;
            height: 334px !important;
        }

  }
  @media (min-width: 768px) and (max-width: 768px){
    #chartFluid{
        margin-top: -2% !important;
        min-width: 364px !important;
        height: 334px !important;
    }
  }
  @media (min-width: 425px) and (max-width: 425px){
    #chartFluid{
        margin-top: -5% !important;
        min-width: 364px !important;
        height: 334px !important;
        margin-left: -10% !important;
    }
}
/* Added by Celsia on 13/05/2021 start */
@media (min-width: 425px) and (max-width: 600px){
    #chartFluid{
        margin-top: 0% !important;
        min-width: 364px !important;
        height: 334px !important;
        margin-left: -10% !important;
    }
}
/* end */
