.semicircle {
  position: relative;
  /* width: 100%;
  height: 50px; */
  margin-top: 20px;
  /* Adjust this value to move the semicircle down */
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.semicircle::before {
  content: "";
  position: absolute;
  top: 0;
  width: 83%;
  height: 43%;
  background-color: hsl(0deg 0% 100%);
  border-radius: 0 0 50% 50%/0 0 100% 100%;
  background-image: url("../../utils/assets/financialPlaning.png");
  background-size: 50%;
  /* or contain, depending on your needs */
  background-repeat: no-repeat;
  background-position: center;
  box-shadow: 0 0 0 2px#AF762E;
}

.rounded-button {
  /* padding: 10px 20px; */
  background-color: hsl(36.67deg 100% 92.94%);
  color: #AF762E;
  border: 2px solid #AF762E;
  border-radius: 21px;
padding: 2px;
  /* Set the border-radius to control the rounding of corners */
  cursor: pointer;
  /* min-width: 90%; */
  width: 200px;
  /* height: 15%; */
  display: grid;
  grid-template-columns: 80% 14%;
  justify-content: flex-end;
  align-items: center;
}