.tabs {
  /* width: 100%;
  padding-left: 3%;
  overflow: hidden; */
}
.tabsContainerprofile .tabs .tabsTitleprofile{
  width: revert;  /* Modified 97% to 100% by Celsia */
}
.tabsTitles {
  list-style: none;
  padding: 0px;
  margin: 0;
  display: flex;
/* border-bottom: 1px solid #f1f2f3; */
  right: 5.5%;
  width: 100%;  /* Modified 97% to 100% by Celsia */
  overflow: hidden;
  background-color: #ffffff;
  /* z-index: 999; */
  margin-top: 85px;
}

.tabsTitleprofile {
  list-style: none;
  padding: -1px;
  margin: 0;
  display: flex;
  border-bottom: 1px solid #f1f2f3;
  right: 5.5%;
  width: 94%;
  background-color: #ffffff;
  z-index: 999;
  /* margin-top: 85px; */
}
.tabimg{
  margin-top: 6px;
}
.ArrowIconLeft{
  margin-left: -4px;
  margin-top:6.7vw;
  padding-left: 7px;
  align-items: center;
}
.ArrowIconRight{
  margin-left: 0px; 
  margin-top:6.7vw;
  padding-left: 7px;
  align-items: center;
}
 @media only screen and (min-width: 350px) and (max-width: 400px) {
  .tabSection{
    margin-top: -80px;
    width: 370px;
    border-bottom: 1px solid #f1f2f3;
    /* margin-left: -30px; */
  }
  .ArrowIconLeft{
    height: 30px;
    margin-top: 145px;
  }
  .ArrowIconRight{
    height: 30px;
   margin-top: 145px;
  }
  .tabHeader{
    background-color: #fff;
    display: inline-block;
    padding: 17px 0;
    color: #31455e;
    cursor: pointer;
    /* padding-right:30px; */
    margin-left: 1px;
    font-size: 14px;
  }
  .tabTitleActive, .mfTabTitleActive{
    color: #af772d;
    padding: 20px 0;
    border-bottom: 3px solid #af772d;
    font-weight: 500;
    display: inline-block;
    cursor: pointer;
    margin-left: 1px;
    font-size: 14px;
  }
  li.tabHeader{
    width:130px;
    margin-top: 45px;
    height: 10px !important;
  }
  li.tabTitleActive{
    width:115px !important;
    margin-top: 45px;
  }
  
  .tabsTitles{
    /* commentedBy yogitha for tabs margintop alignment on 17/05/2021 */
    width:100%;
    overflow-x: auto;
    border: none;
  }
  .tabsTitles::-webkit-scrollbar {
    display: none;
  }
  .tabsTitles{
    -ms-overflow-style: none;  
    scrollbar-width: none;  
  }

  
} 

@media only screen and (min-width:400px) and (max-width: 600px) {
  .tabSection{
    border-bottom: 1px solid #f1f2f3;
    margin-top: -70px;
    width: 400px;
    /* margin-left: -20px; */
  }
  .ArrowIconLeft{
    height: 30px;
    margin-top: 145px;
  }
  .ArrowIconRight{
    height: 30px;
   margin-top: 145px;
  }
  .tabHeader{
    background-color: #fff;
    display: inline-block;
    padding: 17px 0;
    color: #31455e;
    cursor: pointer;
    /* padding-right:30px; */
    margin-left: 1px;
    font-size: 14px;
  }
  .tabTitleActive, .mfTabTitleActive {
    color: #af772d;
    padding: 20px 0;
    border-bottom: 3px solid #af772d;
    font-weight: 500;
    display: inline-block;
    cursor: pointer;
    margin-left: 1px;
    font-size: 14px;
  }
  
  li.tabHeader{
    width:130px;
    margin-top: 45px;
    height: 10px !important;
  }
  li.tabTitleActive{
    width:115px;
    margin-top: 45px;
    height: 20px !important;
  }
  li.mfTabTitleActive{
    width:115px;
    height: 10px !important;
  }
  .tabsTitles{
        /* commentedBy yogitha for tabs margintop alignment on 17/05/2021 */
    /* margin-top: 300px !important; */
    width:100%;
    overflow-x: auto;
    border: none;

  }
  .tabsTitles::-webkit-scrollbar {
    display: none;
  }
  .tabsTitles{
    -ms-overflow-style: none;  
    scrollbar-width: none;  
  }
  
} 


@media only screen and (min-width:600px) and (max-width: 800px) {
 
  .ArrowIconLeft{
    height: 30px;
    margin-top: 100px;
  }
  .ArrowIconRight{
    height: 30px;
   margin-top: 100px;
  }
  
} 
@media only screen and (min-width:800px) and (max-width: 1200px) {
 
  .ArrowIconLeft{
    height: 30px;
    margin-top: 105px;
  }
  .ArrowIconRight{
    height: 30px;
   margin-top: 105px;
  }
  
} 
@media only screen and (min-width:1200px) and (max-width: 1500px) {
 
  .ArrowIconLeft{
    height: 30px;
    margin-top: 105px;
  }
  .ArrowIconRight{
    height: 30px;
   margin-top: 105px;
  }
  
} 
@media only screen  and (max-width: 350px)  {
  .tabSection{
    margin-top: -50px;
    width: 330px;
    border-bottom: 1px solid #f1f2f3;
    /* margin-left: -30px; */
  }
  .ArrowIconLeft{
   height: 30px;
   margin-top: 110px;
  }
  .ArrowIconRight{
    height: 30px;
    margin-top: 110px;
  }
  .tabHeader{
    background-color: #fff;
    display: inline-block;
    padding: 17px 0;
    color: #31455e;
    cursor: pointer;
    /* padding-right:30px; */
    margin-left: 1px;
    font-size: 14px;
  }
  .tabTitleActive, .mfTabTitleActive {
    color: #af772d;
    /* padding: 20px 0 !important; */
    /* border-bottom: 3px solid #af772d; */
    font-weight: 500;
    display: inline-block;
    cursor: pointer;
    margin-left: 1px;
    font-size: 14px;
    
  }
  
  li.tabHeader{
    width:130px;
    margin-top: 45px;
    height: 10px !important;
  }
  li.tabTitleActive{
    width:115px;
    margin-top: 45px;
  }
  
  .tabsTitles{
        /* commentedBy yogitha for tabs margintop alignment on 17/05/2021 */
    /* margin-top: 370px !important; */
    width:100%;
    overflow-x: auto;
    border: none;
    margin-top: 50px;
  }
  .tabsTitles::-webkit-scrollbar {
    display: none;
  }
  .tabsTitles{
    -ms-overflow-style: none;  
    scrollbar-width: none;  
  }
  
}
/* @media only screen and (min-width: 400px) and (max-width:450px) {
  .tabsTitles{
    margin-top: 430px !important;
    width:100%;
    overflow-x: auto;
  }
  .tabsTitles::-webkit-scrollbar {
    display: none;
  }
  .tabsTitles{
    -ms-overflow-style: none;  
    scrollbar-width: none;  
  }
   */
   @media only screen and (min-width:320px) and (max-width: 600px) {
    .tabContainerMobile{
      margin: "20px";
    }
  }
li.tabTitle {
  width: max-content;
}
li.tabTitleprofile {
}
/* @media (max-width: 1024px) {
  .tabsContainer{
    width: 75%;
    margin: auto;
  }
}
@media (max-width: 1440px) {
  .tabsContainer{
    width: 85%;
    margin: auto;
  }
}
@media (max-width: 2560px) { */
  .tabsContainer{
    width: 79%;
    margin: auto;
  }
  .tabsContainerprofile{
    width: 100%;
    /* margin: auto; */
  }
/* } */
@media only screen and (min-width:320px) and (max-width: 600px) {
  .tabsContainer{
    width: 100%;
    margin: auto;
  }
  .tabSection{
    width: 100%;

  }
}

.tabTitle {
  background-color: #fff;
  display: inline-block;
  padding: 19px 0;
  color: #31455e;
  cursor: pointer;
  /* padding-right:30px; */
  margin-left: 1px;
  font-size: 14px;
}

.tabTitleprofile {
  background-color: #fff;
  display: inline-block;
  padding: 19px 0;
  color: #31455e;
  cursor: pointer;
  /* padding-right:30px; */
  margin-left: 1px;
  font-size: 14px;
}
.tabTitleActive, .mfTabTitleActive{
  color: #af772d;
  padding: 19px 0;
  border-bottom: 3px solid #af772d;
  font-weight: 500;
  display: inline-block;
  cursor: pointer;
  margin-left: 1px;
  font-size: 14px;
}

/* .tabContent {
  padding: 5px;
  margin: 0;
  padding-top: 50px
} */

.tabHeaderContent {
  /* background-color: #f5f5f5; */
  padding: 0px 0;
  margin: 0;
  width:"100%";
}
.tabHeaderContentprofile {
  /* background-color: #f5f5f5; */
  /* padding: 5px 0; */
  margin: 0;
}
.link {
  display: inline-block;
  text-decoration: none;
  font-size: 20px;
  margin: 30px;
  padding: 10px;
  border-radius: 5px;
  background-color: #f55a42;
  color: #fff;
}
@media only screen  and (max-width: 600px)  {
  
  .tabHide{
    display: none;
  }
  .tabShow{
    display: block !important;   
  } 
  
}
.tabShow{
  display: none;
} 

/* styles added by yogitha for mobile responsive on 04/06/2021 */
@media only screen and (min-width:320px) and (max-width: 600px) {
  .tabsTitleprofile {
    white-space: nowrap;
    list-style: none;
    padding: -1px;
    margin: 0;
    display: flex;
    border-bottom: 1px solid #f1f2f3;
    right: 5.5%;
    width: 87%;
    background-color: #ffffff;
    z-index: 999;
    max-width:100%;
    overflow-x: scroll;
    border-radius: 10px;
}
.tabTitleprofile {
  background-color: #fff;
  display: inline-block;
  /* padding: 3px 0; */
  color: #31455e;
  cursor: pointer;
  margin-left: 1px;
  font-size: 14px;
}
.tabTitleActive, .mfTabTitleActive {
  color: #af772d;
  /* padding: 3px 0; */
  border-bottom: 3px solid #af772d;
  font-weight: 500;
  display: inline-block;
  cursor: pointer;
  margin-left: 1px;
  font-size: 14px;
}
::-webkit-scrollbar {
  display: none;
}
}

/* Design added by Muthu Lakshmki K */
/* 
.MuiTab-textColorPrimary.Mui-selected {
  color: #af762e !important;
} */

/* .PrivateTabIndicator-colorPrimary-3, .PrivateTabIndicator-colorPrimary-1475, .PrivateTabIndicator-colorPrimary-1862 {

  background-color: #af762e !important;
}

.PrivateTabIndicator-colorPrimary-114, .PrivateTabIndicator-colorPrimary-115, .PrivateTabIndicator-colorPrimary-449 {
  background-color: #af762e !important;
}

.PrivateTabIndicator-colorPrimary-8051, .PrivateTabIndicator-colorPrimary-18613, .PrivateTabIndicator-colorPrimary-2922, .PrivateTabIndicator-colorPrimary-8075 {
  
  background-color: #af762e !important;
} */

.MuiTabs-root {
  background-color: white !important;
}

.MuiTabs-flexContainer {
    display: flex;
    border-bottom: none !important;
    /* overflow-x: scroll; */
    
}

.MuiTabs-scrollable {
  /* border-bottom: solid 1px #e4e5e8 !important; */
  /* margin-bottom: 1px !important; commentted by dulcy*/
}