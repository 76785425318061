/* @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap'); */

.App {
  text-align: center;
  background-color: #fff;
  /* overflow: scroll;  
  overflow-x: hidden;
  overflow-y: hidden; */
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  /* gap: 30px; */
}

#new-updates {
  display: none;
  position: fixed;
  bottom: 5px;
  margin-left: 5px;
  padding: 8px;
  border-radius: 5px;
  box-shadow: 0 0 20px 0 rgb(19 89 161 / 10%);
  background-color: #FFF;
  color: black;
  /* display: flex; */
  flex-direction: column;
  border: 1px solid brown;
  z-index: 1;
  gap: 10px;
}

.test {
  display: none;
}

.containers {
  background-color: '#cfe8fc';
  height: '240px';
}

.MuiGrid-root.portfolioChartSheets.MuiGrid-container.MuiGrid-spacing-xs-4.MuiGrid-grid-xs-12 {
  width: 100%;
  margin: 0;
}

#input-redemption-amount {
  margin-top: 15px !important;
  margin: 8px;
  display: block;
  width: 100%;
  padding: 5px;
  padding-top: 7px;
  padding-bottom: 7px;
  padding: 4px -2px;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  text-align: left !important;
  font-size: 17px !important;
}

.text-field-cursor {
  caret-color: rgb(175, 118, 46);
}

#input-monthly-amount {
  margin-top: 0px !important;
  margin: 8px;
  display: block;
  width: 100%;
  padding-top: 34px;
  padding: 7.2px;
  padding: 4px -2px;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  text-align: left !important;
  font-size: 14px !important;
}

#input-redemption-amount {
  border: 1px solid #ced4da !important;
}

#input-redemption-amount:disabled {
  border: none !important;
  background-color: #F4F6F9;
}

#input-redemption-amount:focus-visible {
  border-color: #af772d !important;
  outline: none;
}

#input-monthly-amount:focus-visible {
  border-color: #af772d !important;
  outline: none;
}

#input-scheme-in:focus-visible {
  border-color: #af772d !important;
  outline: none;
}

#input-scheme-in {
  margin: 8px;
  display: block;
  width: 94%;
  padding: 7px;
  padding: 4px -2px;
  font-size: 1rem;
  line-height: 1.8;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  text-align: left !important;
  font-size: 14px !important;
}