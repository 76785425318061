.container{
  width: 90%;
  padding: 0;
  height: 65px;
  margin-right: 69px;
  border-bottom: 1px solid #e4e5e8;
}
.countscheme{
  width: 925px;
  display: grid;
  grid-template-columns: repeat(2, 0fr);
  margin-top:20px;
}
.verticaline{
  border-left: 1px solid #e4e5e8;
  height: 64px;
}
.headergrid{
  display: grid;
  grid-template-columns: repeat(7, 2fr);
flex: auto;
margin-top: -5px;
}
.XrayHeader{
  border-bottom: solid 1px #e4e5e8;
}
.verticalLine{
  border-left: 1px ;
  height: 20px;
  position: absolute;
  left: 50%;
  margin-left: -3px;
  top: 0;
}
.sortbypopover{
  height: 120px;
  width:150px;
}
.sortbylist{
  height: 40px;
  color: #333333;
}
.sortbylistclick{
  height: 40px;
  color: #333333;
  background: #faf7f2 !important ;
  color: #af772d !important;
}
.sortbylistclick:hover{
  background: #faf7f2 !important;
  color: #af772d !important;
}
.sortbylist:hover{
  background: #faf7f2 !important;
  color: #af772d;
}

.filterImage:hover{
    cursor: pointer;
    background: url("../../../../assets/img23.png") no-repeat;
}
 /* .SchemeFilterImage:hover{
  cursor: pointer;
  background: url("../../../../assets/img23.png") no-repeat;
}  */
.SchemeFilterImage{
  margin-top: 38px;
  cursor: pointer;
  
}


.filterImage{
  cursor: pointer;
}
.MuiMenu-paper{
  margin-top: 25px;
}
.bottomLine{
  /* border-bottom: 1px solid #f1f2f3; */  /* Commended by Celsia */
  margin-top: 0.5em !important;
  width:100%;
 
  /* margin-left: -6px; */
}

/* @media only screen and (max-width: 600px)   {
  .bottomLine{
    border-bottom: none;
  } 
} */

.Line{
  border-bottom: 1px solid #f1f2f3;
  /* margin-top: 0.5em !important; */
  width:500px;
  margin-left: -30px;
}
.Lines{
  border-bottom: 1px solid #f1f2f3;
  margin-top: 0em !important;
  width:500px;
  margin-left: -30px;
}