 .progress-bar {
  position: relative;
  height: 35px;
  width: 188px;
  /* border-radius: 10px; */
  /* margin-left: 5%; */
  }
  .progress-bar-title{
    margin-top: 8px;
    text-align: left;
    font-size: 15px;
   
  }
  #progressBarDiv{
    margin-bottom: 5%; 
    display: grid; 
    grid-template-columns: repeat(3,40%);
    justify-content: center;
  }
  .customtooltip{
  height: auto;
    min-width:220px;
    background-color: white;
    border-left-style: solid;
  border-left-width: 5px;
  border-radius: 5px;
  box-shadow: 0px 0px 0px 4px rgb(19 89 161 /4%);
  }

  .filler {
    height: 100%;
    border-radius: inherit;
    transition: width .2s ease-in;
  }
  .percentagestyle{
    margin-top: 10px;
    font-weight: bold;
    font-size: 15px;
    text-align: right;
    padding-right: 17;
    word-wrap: break-word;
    /* margin-left: -75px; */
  }
  .separatepercentage{
    margin-top: 10px;
    font-weight: bold;
    font-size: 15px;
    text-align: right;
    padding-right: 17;
    word-wrap: break-word;
  }
  /* button {
    margin-right: 10px;
    padding: 7px 12px;
    font-size: 14px;
    background: #d14836;
    color: white;
    cursor: pointer;
    outline: 0;
  } */

  .checkBoxOuter {
    display: flex;
    padding: 2px; 
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 6px;
    width: 3%;
    border:1px solid rgb(219,132,61)
}
.checkBoxInner {
    width: 100%;
    height: 100%;
    background-color: rgb(219,132,61);
}
  
  #vids {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  #vids a:first-of-type {
    margin-bottom: 5px;
  }

  @media (max-width: 1024px) {
    #root{
      padding-right: revert;
    }
    #chartfluid{
        min-width: 265px !important;
    }
    #progressBarDiv{
      margin-bottom: 5%; 
      display: grid; 
      grid-template-columns: repeat(3,35%);
      justify-content: flex-end;
    }
  }

  @media (max-width: 1080px) {
    #root{
      padding-right: revert;
    }
    #chartfluid{
      min-width: 300px !important;
    }
    .progressBarDiv{
      margin-bottom: 5%; 
      display: grid; 
      grid-template-columns: repeat(3,35%);
      justify-content: flex-end;
    }
  }
  @media (max-width: 1100px) {
    #root{
      padding-right: revert;
    }
    #chartfluid{
      min-width: 300px !important;
    }
  }
  @media (max-width: 1200px) {
    #root{
      padding-right: revert;
    }
    #chartfluid{
      min-width: 300px !important;
      margin: -7% -7%;
    }
  }
  @media (max-width: 1000px) {
    #root{
      padding-right: revert;
    }
    #chartfluid{
      min-width: 300px !important;
      margin: revert;
    }
  }
  .ProgressBar{
    display: none;      
  }
  @media only screen and (max-width: 450px)   {
    .ProgressBarBlock{
      display: none;
    }
    .ProgressBar{
      display: block;      
    }
    /* .ProgessDesign{
      margin-left: 30px;
  
    } */
  }
  .progressBarDiv{
    margin-bottom: 5%;
    display: grid;
    justify-content: flex-start;
    grid-template-columns: 25% 37% 20% 16%;
    /* gap: 7px; */
    /* column-gap: 5%; */
  }
  @media only screen and (max-width: 770px) {
    .progressBarDiv{
        margin: 0% 5% 5% 5%;
      display: grid;
      justify-content: flex-start;
      grid-template-columns: repeat(auto-fill, 33% [col-start] 33% [col-middle] 33% [col-end]);
      column-gap: 5%;
    }
  }
  @media only screen and (max-width: 600px) {
      .progressResponsive{
        display: grid;
        grid-template-columns:  repeat(2,40%);
        justify-content: center;
      }
    .progressBarDiv {
        margin: 0% 5% 5% 0%;
        display: grid;
        justify-content: center;
        grid-template-columns: unset;
        /* grid-column-gap: 0%; */
        /* column-gap: 0%; */
    }
    .progress-bar-title {
        margin-top: 8px;
        text-align: left;
        font-size: 15px;
    }
    .progress-bar {
        position: relative;
        height: 35px;
        text-align: left;
        width: 188px;
        /* border-radius: 10px; */
        /* margin-left: 5%; */
        }
        .ProgessDesign{
            display: flex;
    flex-direction: row;
    margin-bottom: 10%;
    /* justify-content: center; */
        }
}
