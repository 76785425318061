.rdrDay {
    box-sizing: inherit;
    width: calc(100% / 9) !important;
    position: relative;
    font: inherit;
    cursor: pointer;
}
span.rdrWeekDay {
    height: 40px !important;
    width: 40x !important;
    min-width: 40px !important;
    min-height: 40px !important;
}
button.rdrDay {
    width: 47px !important;
    height: 48px !important;
    text-align: center;
}

/* button {
    margin-right: 0px !important;
    padding: 7px 12px;
    font-size: 14px;
    color: white;
    cursor: pointer;
    outline: 0;
} */
button .rdrStartEdge,
button .rdrEndEdge {
    border-radius: 2em !important;
    left: 4px;
    right: 4px;
    top: 4px;
    bottom: 4px;
}

.rdrDayToday .rdrDayNumber span:after {
    content: '';
    position: absolute;
    /* bottom: 4px;
    left: 50%; */
    transform: translate(-50%, 0);
    /* width: 18px;
    height: 2px; */
    opacity: 0%;
    border-radius: 2px;
    /* background: #af772d; */
}

.rdrDayHovered {
    /* color: #af772d; */
}
button .rdrDayNumber {
    width: 47px !important;
    height: 48px !important;
}

.rdrDayNumber {
    outline: 0;
    font-weight: 500;
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    bottom: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.rdrDayStartOfMonth .rdrInRange,
.rdrDayStartOfMonth .rdrEndEdge,
.rdrDayStartOfWeek .rdrInRange,
.rdrDayStartOfWeek .rdrEndEdge {
    border-top-left-radius: 0em;
    border-bottom-left-radius: 0em;
    border-top-right-radius: 0em;
    border-bottom-right-radius: 0em;
}

.rdrDayEndOfMonth .rdrInRange,
.rdrDayEndOfMonth .rdrEndEdge,
.rdrDayEndOfWeek .rdrInRange,
.rdrDayEndOfWeek .rdrEndEdge {
    border-top-left-radius: 0em;
    border-bottom-left-radius: 0em;
    border-top-right-radius: 0em;
    border-bottom-right-radius: 0em;
}

.rdrStartEdge,
.rdrEndEdge {
    /* background: #af772d !important; */
    color: white;
}

.rdrDayStartPreview rdrDayEndPreview {
    /* background: #af772d !important; */
}
span.rdrDayNumber:after {
    border-radius: 2em !important;
    top: 2px !important;
    bottom: 2px !important;
    left: 2px !important;
    right: 2px !important;
    /* color: #af772d !important; */
}
span.rdrDayStartPreview.rdrDayEndPreview {
    /* color: #af772d !important; */
    /* color: transparent; */
    border-radius: 2em !important;
    top: 2px !important;
    bottom: 2px !important;
    right: 2px !important;
    left: 2px !important;
}

.rdrSelected {
    border-radius: 2.03em !important;
    top: 2px !important;
    bottom: 2px !important;
    right: 2px !important;
    left: 2px !important;
    /* color: #af772d !important; */
}
.rdrEndEdge {
    border-radius: 2em !important;
    left: 5px;
    right: 5px;
}

.rdrStartEdge {
    border-radius: 2em !important;
    left: 5px;
    right: 5px;
}
button.rdrDay {
    height: 48px !important;
}

.rdrWeekDay {
    height: 48px !important;
    width: 42px !important;
}
.rdrStartEdge {
    border-top-left-radius: 2em !important;
    border-bottom-left-radius: 2em !important;
    left: 2px;
}

.rdrDay:not(.rdrDayPassive) .rdrInRange~.rdrDayNumber span,
.rdrDay:not(.rdrDayPassive) .rdrStartEdge~.rdrDayNumber span,
.rdrDay:not(.rdrDayPassive) .rdrEndEdge~.rdrDayNumber span,
.rdrDay:not(.rdrDayPassive) .rdrSelected~.rdrDayNumber span {
    color: white !important;
}

.SIP-DatePic .rdrMonthAndYearWrapper {
    display: none !important;
}

.SIP-DatePic .rdrDayDisabled {
    display: none !important;
}

.SIP-DatePic .rdrWeekDays {
    display: none !important;
}

.rdrMonths {
    display: flex;
    flex-direction: row;
}

.rdrMonthAndYearWrapper {
    width: 95%;
}

@media only screen and (min-width: 320px) and (max-width:600px) {
    .rdrMonth {
        width: 100%;
        padding: 0px 0.05em 0.100em 0.05em;
    }

    .rdrMonths {
        display: flex;
        flex-direction: column;
    }

    .rdrMonthAndYearWrapper {
        width: 95%;
    }
}