.tableContainerScroll {
    position: relative;
    width: 100%;
    z-index: 1;
    margin: auto;
    overflow: auto;
    height: 400px;
    cursor: context-menu;
}

.tableContainerScroll table {
    width: 100%;
    min-width: 450px;
    margin: auto;
    border-collapse: separate;
    /* border-spacing: 10px;  */
}

.tableContainerResponsive{
    display: none !important;
}

/* .mobileResponsive{
    display: none !important;
} */

#tbl_fundDetails {
    /* overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis; */
    margin-top: -3em;
}

#tbl_fundDetails tr td {
    /* overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis; */
}

.horizontalLine {
    border-bottom: 1px solid #f1f2f3;
    margin-top: 0.6em !important;
    width: 100%;
}

 ::-webkit-scrollbar {
    width: 7px;
    height: 7px;
    cursor: pointer;
  }
  
  @media only screen and (min-width: 600px) and (max-width: 1000px) {
    .tablecontainer{
      width: 100%;
      overflow-x: auto;
      display: block; 
    } 
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1; 
  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #c7c7c7; 
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #c7c7c7; 
  }
  
  .tableContainerScroll thead th {
    background: #ffffff;
    color: #808080;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    font-weight: 500;
    font-size: 12px;
}

.ProfitHeaderBlock{
    /* background-color: red; */
    height: 50;
}

/* safari and ios need the tfoot itself to be position:sticky also */

.tableContainerScroll tfoot,
.tableContainerScroll tfoot th,
.tableContainerScroll tfoot td {
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
    background: #3d4348;
    color: #fff;
    z-index: 4;
    font-size: 18px;
}

.tablecontainer {
    border: none;
    width: 100%;
    border-collapse: separate;
    border-spacing: 0 0.2em;
    /* padding-top: 2em !important; */
    margin-bottom: 10%;

}

.tablecontainer th {
    /* font-size: 12px!important;
    text-transform: uppercase;
    text-align: right!important; commanted by dulcy
    color: #808080;
    font-weight: 500; */
}

.tablecontainer th:nth-child(1) {
    text-align: left!important;
}

.tablecontainer td
 {
    padding: 18px;
    text-align: right;
    font-size: 18px;
}

.tablecontainer tfoot tr{
    background-color: #3d4348; 
    color: #fff;
    font-weight: 500; 
    
} 

#footerFirst{
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem !important;
}

#footerLast{
    border-bottom-right-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
}

.tablecontainer tbody {
    overflow-y: auto;
    height: 120px!important
}

.tablecontainer td .checkboxfillcolor {
    width: 14px;
    height: 14px;
    margin-top: 5px;
}

.tablecontainer tbody td {
    /* border-top:1px solid rgb(153, 146, 146);
    border-bottom:1px solid rgb(153, 146, 146); */
    /* height: 1.7em; */
}

@media only screen and (max-width: 450px)  {
    .tablecontainer tbody td {
        width: 500px;
    }
}

.profitlossSection{
    display: none;
}

@media only screen and (max-width: 600px)   {
    .profitlossBlock{
        /* display: none; */
    }
    .profitlossSection{
        display: block;
    }
    .mobileResponsive{
        display: block;
    }
    .tableContainerBlock{
        display: none !important;
    }
    .tableContainerResponsive{
        display: block !important;
        margin-top: 10px;
        padding: 0px 10px;
    }
    
    .contentProfitLoss, .mfOrder{
      box-shadow: 0 0 5px 0 rgb(19 89 161 / 6%);
      border: solid 1px #ecf3fc;
      background-color: #ffffff; 
      padding: 15px 0;
      border-radius: 8px;
      /* margin-bottom: 2px; */
      
    }
    .mfOrderBlock{
      display: flex;
    }
    .mfOrderLeft{
        flex: 50%;
        padding: 10px;
        text-align: left;
    }
    .mfOrderRight{
        flex: 50%;
        padding: 10px;
        text-align: right !important;
        /* width:130px; */
        float:right !important;
    }
    .contentProfitLossValue{
        /* padding-left: 20px !important;
        padding-right: 20px !important; */
        padding: 10px;
    }
    .contentProfitschemeName{
        /* padding-top: 10px; */
        text-align: left;
    }
    .contentProfitLossBlock{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        width: 100%;
        padding-top: 10px;
        text-align: left;
        
    }
    .contentProfitLossLeft{
        display: flex;
        flex-direction: column;
        flex-basis: 200%;
        flex: 1;
        
    }
    .ProfitLossContent{
        font-size: 15px;
        float: left;
        padding-top: 5px;
        font-weight: 450;
    }
    .SchemeContent{
        font-size: 14px;
        float: left;
        font-weight: 450;
    }
    .contentHeader{
        font-size: 14px;
        font-weight: normal;
        color: #808080;
    }
    .rightHeader{
       
       text-align: right;
    }
    .rightFooter{
        font-size: 14px;
        text-align: right !important;
        color:#808080; 
       
    }
    .profitfooterfontsize{
        font-size: 18px;
        text-align: left !important;
        color:white;
    }
    .FooterHeader{
        font-size: 14px;
        text-align: left !important;
        color: #808080;
    }
    
    .contentGain{
        padding-top: 35px;
        text-align: left;
    }
    .contentProfitLossFooter{
      box-shadow: 0 0 5px 0 rgb(19 89 161 / 6%);
      background-color:#3b3b3b; 
      padding: 15px 0;
      border-radius: 8px;
      margin-bottom: 2px;
    }
}

.tablecontainer tfoot tr td:nth-child(1) {
    text-align: left;
}

.tablecontainer tbody tr {
    /* padding:10px; */
    /* box-shadow: 0 4px 4px 0 rgba(0,0,0,0.2); */
    border-radius: 0.25rem;
    box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.25);
    width: 100%;
}

.iconsize {
    align-self: flex-end!important;
    float: right!important;
    font-size: 25px;
    font-weight: bold;
    color: #4F4F4F;
    margin-top: 3px;
    position: absolute;
    right: 100px;
    height: 25px
}

.verticalLine {
    background-color: #f1f2f3;
    color: #f1f2f3;
    -webkit-transform: rotate(90deg);
    position: absolute;
    width: 100px;
    height: 1px;
    right: 100px;
    border: 1px;
}

.greencolorwithbold {
    /* color: #50B121; */
    font-weight: 500;
    font-size: 14px !important;
}

.greencolor {
    color: #50B121;
    font-size: 14px !important;
    padding-left: 3px !important;
}

.darkgreencolor {
    color: #1B934B;
    font-size: 18px !important;
    padding-right: 10px;
}

.fontbold {
    font-weight: 500;
}

.rupeecolor {
    color: #909090 !important;
    font-size: 14px !important;
}

.rupeesize {
    font-size: 17px !important;
}

.fontcolor {
    color: #333 !important;
    font-weight: 500;
    font-size: 14px !important;
}

.mt1 {
    margin-top: 0.5em;
    margin-bottom: 0.5em;
    display: flex;
    width: 91%;
    /* font-size: 14px !important; */
    color: rgb(144, 144, 144)
}

.mt2 {
    margin-top: 0.5em;
    margin-bottom: 0.5em;
    display: flex;
    width: 71%;
    /* font-size: 14px !important; */
    color: rgb(144, 144, 144)
}

.mt2 {
    margin-top: 0.5em;
    margin-bottom: 0.5em;
    display: flex;
    width: 90%;
    /* font-size: 14px !important; */
    color: rgb(144, 144, 144)
}

.mtminus1 {
    margin-top: -1.3em;
    font-weight: bold;
}

.footerfontsize {
    font-size: 18px !important;
}

.footerfontsizeseconddiv {
    font-size: 12px;
    margin-top: 13px;
    color: #909090 !important
}

.footerbagessize {
    background-color: #1B934B !important;
    font-size: 12px !important;
    margin-top: 8px !important;
    color: white !important;
    min-height: 14px !important;
    height: auto !important;
    width: auto !important;
    border-radius: 25px !important;
    /* //background-color: blue; */
    float: right !important;
    padding: 2px !important;
    display: none !important;
}

.filterpopoverdiv {
    width: 300;
    height: 300;
}

/* @media only screen and (max-width: 1024px)   {
.tblHeaderSchema{
    background-color: red !important;
    width:200px !important;
}
} */

.topbar{
    display:'flex';
    color:'#a7a7a7';
    font-weight:400;
    justify-content:'space-between';
    width:'100%';padding:'10px';
}

.tableshadow {
    box-shadow: 0 0 5px 0 rgba(19, 89, 161, 0.2) !important;
    border: solid 1px #ecf3fc !important;
    padding: 15px 0 !important;
    border-radius: 5px !important;
    margin-bottom: 2px !important;
}

.tableheader {
    font-size: 12px !important;
    font-weight: 500 !important;
   
}

.pt15 {
    padding-top: 15px !important;
}

.v-line {
    border-left: 1px solid rgb(228, 229, 232);
    height: 72px;
    position: absolute;
    margin-top: -15px;
    margin-left: -4px;
    ;
}

.filterIcon {
    margin-left: 11px;
    float: right;
    margin-top: 5px;
    cursor: pointer;
}

.green {
    color: #13a84c !important;
    font-size: 14px !important;
    font-weight: 500 !important;
}

.tablecontainers th {
    font-size: 12px!important;
    text-transform: uppercase;
    text-align: right!important;
    color: #808080;
    font-weight: 500;
}

.tablecontainer tbody tr {
    border-radius: 0.25rem;
    box-shadow: 0px 1px 2px 1px #e0e5eb !important;
    /* box-shadow: 0px 1px 1px 0px  rgba(0, 0, 0, 0.25) !important; */
    width: 100%;
}

.filterpop {
    position: absolute !important;
    z-index: 1 !important;
}



/* styles added by yogitha on 02/06/2021  for mobile responsive */
.desktopResponsive {
    display: block
    /* visibility:visible */
}

/* styles added by yogitha on 01/06/2021 for mobile responsive */
@media only screen and  (min-device-width: 320px) and (max-device-width: 600px) {
   
    .desktopResponsive {
            display: none
            /* visibility:hidden */
    }
    .mobileResponsive {
            display: block;
            margin-left: 10px;
            /* padding: 10px; */
            /* visibility:visible */
    }
    .cardSpareaterLine {
        height: 0.5;
        width: 100%;
        background-color: "#c7c7c7";
        margin-top: 2%;
    }
}


/*Responsive created by Muthu Lakshmi K*/

@media only screen and (min-width:600px)  {
    .mobileResponsive{
        display: block;
    }
   
}
@media only screen and (min-width:300px) and (max-width:350px) {
    .contentProfitLoss,.mfOrder{
        width: 96% !important;
        margin-left: 5px !important;
        
    }
   
}
@media only screen and (min-width:350px) and (max-width:600px) {
    /* .contentProfitLoss,.mfOrder{
        width: 97% !important;
        margin-left: 5px !important;
        
    } */
}
/* @media only screen and (min-width:400px) and (max-width:600px) {
    .contentProfitLoss,.mfOrder{
        width: 98% !important;
        margin-left: 5px !important;
    }
} */

