.icons:after {
  content: '\2807'; 
  font-size: 25px;
  margin-left: 25px;
  color: #909090;
  cursor: pointer;
  margin-right: 10px;
}
.iconsCard:after{
  content: '\2807'; 
  font-size: 25px;
  margin-left: 25px;
  color: #909090;
  cursor: pointer;
  margin-right: 10px;
}
.contentBlock{
  margin-top: 20px !important;
}

@media only screen and  (min-width: 600px) and (max-width: 1024px) {
  .contentBlock{
    width: 100%;
    overflow-x: auto;
    display: block;
  } 
 
}
@media only screen and  (min-width: 1000px) and (max-width: 1400px) {
  .iconsCard:after{
    margin-left: 45px;
  }
 
}
/* max-width changed to 928 from 600 by dulcy*/

 @media only screen and (max-width: 928px)   {
  /* .transactionBlock{ 
    overflow-x:auto;
    width:100%;  
    border-top: solid 1px #e4e5e8;
    border-bottom: solid 1px hsl(225, 8%, 90%);
    -ms-overflow-style: none;  
    scrollbar-width: none;  
  }
 .transactionBlock::-webkit-scrollbar {
    display: none;
  }  */
  /* .transactionBlock {
    -ms-overflow-style: none;  
    scrollbar-width: none;  
  }  */
  .LapScreen{
    display: none;
  }
  .MobileScreen{
    display: block !important;
  }
  .contentTransTable{
      box-shadow: 0 0 5px 0 rgb(19 89 161 / 6%);
      border: solid 1px #ecf3fc;
      background-color: #ffffff; 
      padding: 15px 0;
      border-radius: 8px;
      margin-bottom: 2px;
  }
  .contentHeader{
    font-size: 14px;
    font-weight: normal;
    color: #808080;
    float: left;
  }
  .contentTransValue{
    padding: 10px !important;
  }
  .transContent{
    font-size: 15px;
    float: left;
    padding-top: 5px;
  }
 .contentTranschemeName{
    padding-top: 35px;
    text-align: left;
  } 
  
 .contentTransactionBlock{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    padding-top: 25px;
    text-align: left;
  } 
   .contentTransLeft{
    display: flex;
    flex-direction: column;
    flex-basis: 200%;
    flex: 1;
  }
  .rightContent{
    text-align: right;
  }
    
} 

.MobileScreen{
  display: none;
}
/* .transactionBlock{
  background-color: red !important;
} */




.contentcolDateValue{
  justify-content: flex-start ;
  color: #333;  
  font-size: 14px;
  flex: 1 ;
  font-weight: 500;
  /* width: 103px; */
  margin-top: auto;
  margin-bottom: auto;
  width:100px; 
  }

  .contentcolDateValuemandate{
    justify-content: flex-start ;
    color: #333;  
    font-size: 14px;
    flex: 1 ;
    font-weight: 500;
    margin-top: auto;
    text-align: left;
    padding-left: 10px;
    margin-bottom: auto;
    width:88px; 
    }
  .contentcolDateHeader{
    /* color:#808080; */
    justify-content: flex-start ;
    flex: 1;
    /* width: 103px; */
    width:100px;
  }
  .contentcolDateHeadermandate{
    color:#808080;
    justify-content: flex-start ;
    flex: 1;
    padding-left: 10px;
    /* width: 103px; */
    /* width:91px; */
  }
  
  .contentcolAmount{
    font-weight: 500;
    justify-content: flex-end ;
    color:#333;
    flex: 1 ;
    text-align: right;
    /* width: 76px; */
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .contentcolAmountTrade{
    font-weight: 500;
    justify-content: flex-end ;
    color:#333;
    flex: 1 ;
    text-align: left;
    /* width: 76px; */
    margin-top: auto;margin-bottom: auto;
  }
  .contentcolMediumfont{
    justify-content: flex-end ;
    color:#333;
    text-align: right !important;
     width: 120px; 
    margin-top: auto;margin-bottom: auto;
    
   
  } 
  /* width: 80 to 81px;  changed by dulcy */
  .contentcolMediumfontmandate{
    justify-content: flex-end ;
    color:#333;
    text-align: right !important;
     width: 81px; 
    margin-top: auto;margin-bottom: auto;
  }
  .contentcolMediumfontLeft{
    /* color:#808080; */
    justify-content: flex-start ;
    flex: 1;
    width: 120px; 
    
    /* text-align: center !important; */
  }
  .contentcolMediumfontaccount{
    /* color:#808080; */
    justify-content: flex-start ;
    flex: 1;
    /* width: 100px;  */
    text-align: right ;
  }
    
  .contentcolMediumfontTradeLeft{
    color:#808080;
    justify-content: flex-start ;
    flex: 1;
    width: 153px; 
    text-align: left !important;
  }
  .contentcolMediumfontLeftAssetClass{
    color:#909090;
    justify-content: flex-start ;
    flex: 1;
    width: 120px; 
    margin-top: auto;margin-bottom: auto;    
    text-align: center !important;
  }
  .contentcolMediumfontLeftAssetClassaccount{
    color:#909090;
    justify-content: flex-start ;
    flex: 1;
    width: 100px; 
    margin-top: auto;
    margin-bottom: auto;    
    text-align: center ;
  }
  .contentcolMediumfontLeftAssetClassTrade{
    color:#909090;
    justify-content: flex-start ;
    flex: 1;
    width: 106px; 
    margin-top: auto;margin-bottom: auto;    
    text-align: right !important;
  }
  
  .contentTransactionTypeHeader{
    /* color:#808080; */
    justify-content: flex-start;
    width: 179px; 
    flex: 1;
    text-align: center !important;
  }
  .contentTransactionTypeHeadermandate{
    color:#808080;
    justify-content: flex-start;
    width: 100px; 
    flex: 1;
    text-align: left !important;
  }
  .contentTransactionTypeHeaderaccount{
    /* color:#808080; */
    justify-content: flex-start;
    width: 100px; 
    flex: 1;
    text-align: right ;
  }
  .contentTransactionTypeHeaderaccountResponsive{
    color:#808080;
    justify-content: flex-start;
    /* width: 100px;  */
    flex: 1;
    text-align: right ;
  }

  .contentcolTransactionTypeValue{
    color:#909090;
    justify-content: flex-start;
    width: 200px; 
    flex:1;
    margin-top: auto;margin-bottom: auto;
    text-align: left !important;
    margin-left: 20px;
    
  }
  .contentcolTransactionTypeValueaccount{
    color:#909090;
    justify-content: flex-start;
    /* width: 100px;  */
    flex:1;
    margin-top: auto;margin-bottom: auto;
    text-align: right !important;
  }
  .contentcolTransactionTypeValueTrade{
    color:#909090;
    justify-content: flex-start;
    width: 158px; 
    flex:1;
    margin-top: auto;margin-bottom: auto;
    text-align: left !important;
  }
  
  .contentcolNumericHeader{
    /* color:#808080; */
    justify-content: flex-start ;
    flex: 1;
     text-align: right;
     width: 100px; 
  }
  .contentcolNumericHeaderTrade{
    color:#808080;
    justify-content: flex-start ;
    flex: 1;
     text-align: right;
     width: 106px; 
  }
  .contentcolUnitHeader{
    /* color:#808080; */
    justify-content: flex-start ;
    flex: 1;
    text-align: right !important;  /*   alignment center to right by dulcy */
    width: 101px;                     /*   width 100 to 85 by dulcy */
  }
  .contentcolUnitHeaderaccount{
    /* color:#808080; */
    justify-content: flex-start ;
    flex: 1;
    text-align: right !important;
    /* width:100px; */
  }
  .contentcolUnitHeaderTrade{
    color:#808080;
    justify-content: flex-start ;
    flex: 1;
    text-align: left !important;
    width:100px;
  }
  .contentcolNavHeader{
    /* color:#808080; */
    justify-content: flex-start ;
    flex: 1;
    text-align: right !important;  /*   alignment center to right by dulcy */
    width:96px !important;                       /*   width 100 to 85 by dulcy */
  }
.contentcolNavHeaderTrade{
  color:#808080;
  justify-content: flex-start ;
  flex: 1;
  text-align: left !important;
  width:100px;
}
  .contentcolFolioNumberHeader{
    /* color:#808080; */
    justify-content: flex-end ;
    flex: 2 ;
    text-align: center !important;
    width: 140px; 
  }
  
  .contentcolFolioNumberHeaderaccount{
    /* color:#808080; */
    justify-content: flex-end ;
    flex: 2 ;
    text-align: right !important;
    width: 100px; 
  }
  .contentcolFolioNumberHeaderTrade{
    color:#808080;
    justify-content: flex-end ;
    flex: 2 ;
    text-align: right !important;
    width: 82px; 
  }
  .contentcolFolioNumberValue{
    color:#909090;
    justify-content: flex-end ;
    flex: 2 ;
    text-align: left !important;
     width: 100px; 
    margin-top: auto;margin-bottom: auto;
  }
  .contentcolFolioNumberValueaccount{
    color:#909090;
    justify-content: flex-end ;
    flex: 2 ;
    text-align: right !important;
     width: 100px; 
    margin-top: auto;margin-bottom: auto;
  }
  .contentValueSchemeName{
    width: 370px;
  }

  .contentcolSchemeName{
    color: #333;  
  font-size: 14px;
  width: 337px;
  flex: 4;
  font-weight: 500;
  margin-top: auto;margin-bottom: auto;
  }
  
  .contentcolSchemeNameHeader{
    /* color:#808080; */
    flex: 4;
    width:97px !important;
  }

  @media (min-width:1024px){
    
    .contentcolAmount{
      font-weight: 500;
      justify-content: flex-end ;
      color:#333;
      flex: 1 ;
      text-align: right;
      width: 100px;
      margin-top: auto;
      margin-bottom: auto;
    }

    .contentcolNumericHeader{
      /* color:#808080; */
      justify-content: flex-start ;
      flex: 1;
      text-align: right;
       width: 100px; 
    }
    
    .contentcolSchemeNameHeader{
      /* color:#808080; */
      flex: 4;
      width:97px !important;
    }
    .contentcolSchemeNameTradeHeader{
      color:#808080;
      flex: 4;
      width:153px !important;
      text-align: right;
    }
    .contentcolSchemeName{
      color: #333;  
    font-size: 14px;
    width: 250px !important;
    flex: 4;
    font-weight: 500;
    margin-top: auto;margin-bottom: auto;
    }
    .contentcolSchemeNameTrade{
      color: #333;  
    font-size: 14px;
    width: 146px !important;
    flex: 4;
    font-weight: 500;
    margin-top: auto;margin-bottom: auto;
    text-align: right;
    }


    .contentcolFolioNumberHeader{
      /* color:#808080; */
      justify-content: flex-end ;
      flex: 2 ;
      text-align: center !important;
      width: 100px;
    }
    
    .contentcolFolioNumberValue{
      color:#909090;
      justify-content: flex-end ;
      flex: 2 ;
      text-align: left !important;
      width: 100px;
      margin-top: auto;margin-bottom: auto;
    }



  }
  
  .contentcol2{
    justify-content: flex-end ;
  }
  
  
  .contentbody{
     background-color:#f7f2f200;
     color:#31455E;
     cursor: pointer;
     border-radius: 5px;
     box-shadow:0px 1px 2px 1px #e0e5eb;
  }
  
  
  .contentcolTableValue{
    background-color:#f7f2f200;
    color:#31455E;
    border-radius: 5px;
    box-shadow:0px 1px 2px 1px #e0e5eb;
    display: "flex";
   flex-direction: "row";
   padding-top: 8px;
  padding-bottom: 6px;
  }
  
  .contentcolumn{
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    grid-gap: 1px;
    /* padding: 14px; */
    font-size:12px ;
    font-weight: 500; 
    flex:1;
    text-align: left;
  }
  .contentcolumnaccount{
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-gap: 1px;
    padding: 14px;
    /* padding: 14px;
    font-size:12px ;
    font-weight: 500;  */
    flex:1;
    text-align: left;
  }
  .contentcolumnTrade{
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    grid-gap: 1px;
    padding: 14px;
    font-size:12px ;
    font-weight: 500; 
    flex:1;
    text-align: left;
  }
  .contentcolumnAccountstatement{
    display: grid;
    grid-template-columns: 20% 53% 25%;
    /* grid-template-columns: 29% 50% 25%; */
    /* grid-template-columns: repeat(3, 1fr); */
    grid-gap: 1px;
    padding: 14px;
    font-size:12px ;
    font-weight: 500; 
    flex:1;
    text-align: left;
  }
  .contentcolumnAccountstatementequity{
    display: grid;
    grid-template-columns: 20% 50% 25%;
    /* grid-template-columns: 29% 50% 25%; */
    /* grid-template-columns: repeat(3, 1fr); */
    grid-gap: 1px;
    padding: 14px;
    font-size:12px ;
    font-weight: 500; 
    flex:1;
    text-align: left;
    margin-left:5%;
  }
  .contentcolValue{
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    grid-gap: 1px;
    padding: 14px;
    font-size:14px ;
    flex:1;
    align-items: "center";
    justify-content : "center";
    text-align: left;
  }
  .contentcolValueaccount{
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-gap: 1px;
    padding: 14px;
    font-size:14px ;
    flex:1;
    align-items: "center";
    justify-content : "center";
    text-align: left;
  }
  .contentcolValueTrade{
    display: grid;
    grid-template-columns: repeat(8,1fr);
    grid-gap: 1px;
    padding: 14px;
    font-size:14px ;
    flex:1;
    align-items: "center";
    justify-content : "center";
    text-align: left;
  }
  
  .footerStyle {
    height: 16px;
    text-align: center;
    padding-top: 4px;
    padding-bottom: 4px;
    color: #abb4bf;
    position: absolute;
    width: 100%;
    bottom: 0px;
    background-color: #e7eaec;
    font-size: 14px;
    /* yogitha */
    position: absolute;
    bottom: 0;
    width: 100%;
}

  .contentcolCompanylogo{
  justify-content: flex-start ;
  color: #333;  
  font-size: 14px;
  flex: 1 ;
  font-weight: 500; 
  text-align: left;
  padding-top: 10px;
  padding-left: 20px;
  padding-right: 10px;
  }
  

  .contentcolStatusValue{
    justify-content: flex-start ;
    color: #333;  
    font-size: 14px;
    flex: 1 ;
    font-weight: 500;
    /* width: 200px; */
    float: left;
  }

  .contentcolScheme{
    padding: 20px;
    /* position: absolute; */
  }

  .contentcolSchemeLogo{
    /* width:100px ; */
    height:50px;
    border-radius: 10px;
  }

  .contentcolMFTransactionGrid{
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    grid-gap: 1px;
    padding: 14px;
    font-size:12px ;
    align-items: "center";
    justify-content : "center";
    text-align: left;
    box-shadow: 0px 1px 2px 1px #e0e5eb;
  }
  

  .contentcolTransactionType{
    color:#808080;
    /* justify-content: flex-start ; */
    flex: 4
  }
/* style added by yogitha for mobile responsive on 18/05/2021 */
  .contentcolTableValueResponsive{
    background-color:#f7f2f200;
    color:#31455E;
    border-radius: 5px;
    box-shadow:0px 1px 2px 1px #e0e5eb;
    display: "flex";
   padding-top: 10px;
   margin: 0px 10px;
  /* padding-bottom: 6px; */
  }
  .contentcolMediumfontLeftAssetClassaccountResponsive{
    color:black;
    justify-content: flex-end ;
    flex: 1;
    /* width: 100px;  */
    margin-top: auto;
    margin-bottom: auto;    
    text-align: center ;
  }
  .contentcolMediumfontaccountResponsive{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 25%;
    padding: 14px;
    font-size: 12px;
    font-weight: 500;
    flex: 1 1;
    text-align: left;
  }

  /*Mobile Responsive styles added by Muthu Lakshmi K  */
  @media only screen and  (min-width: 320px) and (max-width: 350px) {
    .transactionBlock{ 
      margin-top: -13px;
    }
    .contentTransTable{
      /* width:300px;
      margin-left: -20px; */
    }
  }
  @media only screen and  (min-width: 900px) and (max-width: 1024px) {
    .contentcolumnAccountstatement{
      display: grid;
      grid-template-columns: 10% 61% 25%;
      /* grid-template-columns: 29% 61% 25%; */
      grid-gap: 1px;
      padding: 14px;
      font-size:12px ;
      font-weight: 500; 
      flex:1;
      text-align: left;
      margin-left:5%;
    }
    .contentcolumnAccountstatementequity{
      display: grid;
      /* grid-template-columns: 29% 61% 25%; */
      grid-template-columns: 20% 55% 25%;
      grid-gap: 1px;
      padding: 14px;
      font-size:12px ;
      font-weight: 500; 
      flex:1;
      text-align: left;
      margin-left:5;
    }
  }  .contentcolumnAccountstatementequity{
    display: grid;
    /* grid-template-columns: 29% 61% 25%; */
    grid-template-columns: 24% 52% 23%;
    grid-gap: 1px;
    padding: 14px;
    font-size:12px ;
    font-weight: 500; 
    flex:1;
    text-align: left;
    margin-left:0;
  }
  @media only screen and  (min-width: 350px) and (max-width: 600px) {
    .transactionBlock{ 
      margin-top: -16px;
    }
    
  }
  /* @media only screen and  (min-width: 1000px) and (max-width: 1024px) {
    .contentcolumnAccountstatement{ 
      margin-top: -16px;
    }
    
  } */
  /* styles added  by yogitha for mobile responsive on 19/05/2021  */
  @media only screen and  (min-width: 320px) and (max-width:928px) {
    .contentcolumnaccount {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 15%;
      padding: 14px;
      font-size: 12px;
      font-weight: 500;
      flex: 1 1;
      text-align: left;
      margin-top: -3%
  }
  .contentcolUnitHeaderaccount{
    color:black;
    justify-content: flex-start ;
    flex: 1;
    text-align: right !important;
    /* width:100px; */
  }
  .contentcolMediumfontaccount{
    color:black;
    justify-content: flex-start ;
    flex: 1;
    /* width: 100px;  */
    text-align: right ;
  }
  .contentcolTransactionTypeValueaccount{
    color:black;
    justify-content: flex-start;
    /* width: 100px;  */
    flex:1;
    margin-top: auto;margin-bottom: auto;
    text-align: right !important;
  }
  .paddingResponsive{
    padding: 0px 10px;
  
  }
  }
  @media only screen and (min-width: 320px) and (max-width: 1000px) {
    .icons:after {
      content: '\2807';
      font-size: 23px;
      margin-left: 15px;
      color: #909090;
      cursor: pointer;
      margin-right: 10px;
  }
  .iconsCard:after{
    content: '\2807'; 
    font-size: 23px;
    margin-left: 15px;
    color: #909090;
    cursor: pointer;
    margin-right: 10px;
  }

  }


  /* @media only screen and  (min-width: 350px) and (max-width: 400px) {
   
    .contentTransTable{
    
    } */
  /* } */