@media only screen and (max-width: 920px)   {
    .RunningSIPHeader{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        width: 100%;
        padding-top: 10px;
        text-align: left;
        padding-bottom: 10px;
        font-size: 12px;
        color: #808080;
        font-weight: 500;
    }
    .RunningSIPHeaderLeft{
        display: flex;
        flex-direction: column;
        flex-basis: 200%;
        flex: 1;
        margin-left: 10px;
    }
    .RunningSIPHeaderRight{
        margin-right: 10px;
        text-align: right;
    }
    .contentSIPBlock{
        box-shadow: 0 0 5px 0 rgb(19 89 161 / 6%);
        border: solid 1px #ecf3fc;
        background-color: #ffffff; 
        padding: 5px 0;
        border-radius: 8px;
        /* margin-bottom: 2px; */
    }
    .SIPfooter{
        display: flex;
    }
    .RunningSIPBlocks{
    display: grid;
    grid-template-columns: repeat(auto-fill, 70% [col-start] 30% [col-end]);

    }
    #SchemeName {
        font-size: 14px;
        font-weight: 500;
        text-align: left;
        min-width: 180px;
    }
    .footerSIPBlock{
        width: 100%;
        border-radius: 6px;
        background-color: #3D4348;
        margin-left: 1px;
        margin-right: 5px;
    }
    .RunningSIPLeft, .SIPfooterLeft{
        flex: 50%;
        padding: 10px;
        text-align: left;
    }
    .RunningSIPRight, .SIPfooterRight{
        flex: 50%;
        padding: 10px;
        text-align: right !important;
        /* width:130px; */
        float:right !important;
    }
    .SipHeader{
        text-align: left;
        color:#909090;
        font-size: 14px;
    }
    .value {
        color: #909090;;
        font-weight: 500;
        font-size: 15px;
    }
    .SIPHeaderResponsive{
        display: block;
        overflow-x:auto;
        width:100%;  
        border-top: solid 1px #e4e5e8;
        border-bottom: solid 1px hsl(225, 8%, 90%);
        -ms-overflow-style: none;  
        scrollbar-width: none; 
    }
}
