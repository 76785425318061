.switchContainers{
    width:160px;
    background-color: #e7eaec;
    height: 40px;
    border-radius: 20px;
    display: flex;
    justify-content: space-evenly;
font-size:14px;
    float: right;
 box-shadow: 0 3px 1px 0 rgba(0, 0, 0, 0.06);
 border: solid 0.5px rgba(189, 189, 189, 0.04);
 cursor: pointer;
}
.switchContainers label{
    color: #31455e;
    text-align: center;
    padding: 4px;
    padding-top: 10px;
    margin-top: 2px;
    margin-left: 2px;
    margin-right: 2px;
   cursor: pointer;
}

.switchContainers  .active{
    color: #af772d;;
    background-color: #ffffff!important;
    width:176px;
    border-radius: 20px;
    cursor: pointer;
}


.switchContainer{
    width:160px;
    background-color: #e7eaec;
    height: 40px;
    border-radius: 20px;
    display: flex;
    justify-content: space-evenly;
font-size:14px;

 box-shadow: 0 3px 1px 0 rgba(0, 0, 0, 0.06);
 border: solid 0.5px rgba(189, 189, 189, 0.04);
 
}
.switcherListHeader{
    font-size: 12px;
    float: none;
    /* padding-left: 13px; */
    padding-left: 20px;
}

.bulletPointContainer{
    width: 11px;
   margin-left: 20px;
}
.containerBlock{
    display: grid;
    grid-template-columns: repeat(3, 20px [col-start] 125px [col-middle] 84px [col-end]);
    margin-left: -20px;
}

@media only screen and (max-width: 350px)   {
    /* .switchContainer{
        margin-right: 80px;
    } */
     /* .bulletPointContainer{
        margin-left: -90px;
    }  */
    .displayBlock{
        /* width: 85px; */
        padding-top: 5px;
        margin-left: -20px;
    } 
    /* .switcherListHeader{
        font-size: 12px;
        float: none;
        padding-left: 13px;
    } */
    
}
@media only screen and (min-width: 350px) and (max-width: 400px )  {
    /* .switchContainer{
        margin-right: 115px ;
    } */
    /* .switcherListHeader{
        font-size: 12px;
        float: none;
        padding-left: 13px;
    } */
    .displayBlock{
        padding-top: 5px;
        margin-left: -40px;
    } 
}



@media only screen and (min-width: 600px) and (max-width: 900px )  {
    .switchContainer{
        margin-right: 0px ;
    }
}

.switchContainer label{
    color: #31455e;
    text-align: center;
    padding: 4px;
    padding-top: 10px;
    margin-top: 2px;
    margin-left: 2px;
    margin-right: 2px;
    
}

.switchContainer  .active{
    color: #af772d;;
    background-color: #ffffff!important;
    width:176px;
    border-radius: 20px;
}

.switcherLabel{
    width:176px;

}
.squareBox {
    height: 10;
    width: 10;
    margin: 10;
    margin-top: 5;
    border: "double";
    border-color: "aliceblue";
}
button.MuiButtonBase-root.MuiButton-root.MuiButton-contained.btn-sidetab
    span {
    text-transform: capitalize !important;
  }

/* Linechart */
.highcharts-range-selector-group{
display: none;
}
/* .highcharts-container {
    margin-left: 4%;
} */
.highcharts-scrollbar{
    display: none;


}
@media only screen and (min-width: 1800px)   {
    .highcharts-container {
        margin-left: 2%;
    }
}
@media only screen and (max-width: 1100px){
    .containerBlock{
        display: grid;
        grid-template-columns: repeat(3, 17px [col-start] 86px [col-middle] 52px [col-end]);
        margin-left: -30px;
    }
}
@media only screen and (min-width: 600px) and (max-width: 900px) {
    .highcharts-container {
        margin-left: 0px;
       
    }
}
@media only screen and (min-width: 320px) and (max-width: 600px) {

    .highcharts-container {
        margin-left: 0px;
        margin-top: -2%;
        
    }
  }