/* .categoryFilter{
  box-shadow: 7px 7px 20px 0 rgba(0, 0, 0, 0.2);
  border: solid 1px #e6e7ea;
  top: 35px;
  border-radius: 8px;
  padding: 15px 20px 20px;
  font-size: 14px;
  color: #8a8885;
  font-weight: 500;
 
} */
/* .schemeFilter{
  box-shadow: 7px 7px 20px 0 rgba(0, 0, 0, 0.2);
  border: solid 1px #e6e7ea;

  top: 35px;
  border-radius: 10px;
  padding: 15px 20px 20px;
  font-size: 14px;
  color: #8a8885;
  font-weight: 500;
  overflow-y: revert !important;
  overflow-x: revert !important;
} */
/* .css-1r4vtzz {
  width: 261px !important;
}

.css-48ayfv {
  width: 261px !important;
  outline:'solid 1px' !important;
  border-radius: '1px solid'!important;
}
.filterHeader{
  font-size: 14px;
  font-weight: bold;
  color: #333333;
 }
 .css-11unzgr {
  max-height: 6em !important;
 }
 
 .mulicheckboxes{
 background-color: white !important;
 border-radius: 4px !important;
 margin-top: -50px !important;
 margin-left: -10px !important;
 position: absolute !important;
 z-index: none !important;
}

 .css-1pcexqc-containers {
  min-width: 295px !important;
  margin-left: -12px !important;
  position: relative !important;
  box-sizing: border-box !important;
  overflow: auto !important;
  max-height: 250px !important;
  box-shadow: 10px 10px 20px 0 rgba(0, 0, 0, 0.2) !important;
  z-index: 100007;
} */


/* .css-ik6y5r {
  box-shadow: 7px 7px 20px 0 rgba(0, 0, 0, 0.2);
 }
.fliterdrop{
  margin-bottom: 20px;
  margin-top: 8px;
 border: 1px solid #c7c7c7;
 
} */
/* .css-1jllj6i-control {
  min-height: 40px !important;
}

.label{
  padding-bottom: 8px;
  font-weight: normal;
  margin-bottom: 20px;
}

.btnButtons{
  text-align: right;
  padding-top: 10px;
} */
/* .filterHeader{
  color:'black';
  font-weight:'bold';
  font-size:14;
  margin-top:0
}
.btnClear{
  background: #f8ecdd;
  padding: 0;
  line-height: 36px;
  color: #656565;
  font-weight: 500;
  font-size: 14px;
  line-height: 36px;
  width: 76px;
  margin-left: 5px;
  border-radius: 5px;
  border: 1px solid transparent;
  
} */

/* .dropdownBorder:hover{
border: 1px solid #af772d;
}

.dropdownBorder:active{
  border: 2px solid #af772d;
} */

/* .btnApply{
  background: #af772d;
  margin-left: 7px;
  padding: 0;
  line-height: 36px;
  color: #fff;
  font-weight: 500;
  font-size: 14px;
  line-height: 36px;
  width: 76px;
  border-radius: 5px;
  border: 1px solid transparent;
} */

.datePicker {
  display: block;
  width: 100%;
  height: 30px;
  width: 339px;
  font-size: 14px;
  line-height: 1.5;
  color: #495057;
  margin-bottom: 10px;
  padding-top: 10px;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  margin-top: 10px;
  text-align: left;
}

.drp-selected {
  display: block;
  float: left;
  font-size: 14px;
  font-weight: 500;
  padding-top: 20px;
  position: relative;
  padding-right: 8px;
  text-align:left
}

.drp-selected::before {
  content: 'Inception';
  font-size: 11px;
  color: #909090;
  position: absolute;
  top: 6px;
}

.closeicon{
  float: right;
}

.closeicon >.MuiSvgIcon-root{
  font-size: 1.5em;
  opacity:0.3;
}

.MuiPopover-paper{
    overflow-x: revert !important;
    overflow-y: revert !important;
}
  
.closeiconDisplayBlock {
  cursor: pointer;
  font-size: 22px;
  color: #414c52;
  z-index: 1;
  text-align: right;
  /* float: right; */
  margin-top: 4px;
  position: absolute;
  margin-left: 10.2em; 
 } 

 .closeiconDisplayBlockTransaction {
  cursor: pointer;
  font-size: 22px;
  color: #414c52;
  z-index: 1;
  text-align: right;
  /* float: right; */
  margin-top: 1.5px;
  position: absolute;
  margin-left: 10.9em;
 } 


 .closeiconDisplayBlock > .MuiSvgIcon-root{
  width: 18px !important;
 }
 .filterscheck button span:last-child{
  display: none;
}
.filterClose{
  padding-right: 7px;
}
.desktopResponsive{
  display: block !important;
    
}
.MobileResponsive{
  display:none !important;
}

  /* styles added  by yogitha for mobile responsive on 19/05/2021  */
  @media only screen and  (min-width: 320px) and (max-width: 350px) {
    .btnButtons{
      display:grid;
      grid-template-columns:100px 78px 25px;
    }
  }
  @media only screen and  (min-width: 360px) and (max-width: 600px) {
    .btnButtons{
      display:grid;
      grid-template-columns:175px 78px 25px;
      gap:1px;
    }
  }
 @media only screen and  (min-width: 320px) and (max-width: 600px) {
  .MobileResponsive{
    display:block !important;
  }
  .desktopResponsive{
    display: none !important;
      
  }
  .categoryFilter{
    box-shadow: 7px 7px 20px 0 rgba(0, 0, 0, 0.2);
    border: solid 1px #e6e7ea;
   /* // background-color: #a85f5f; */
   /* width: 300px; */
    top: 35px;
    border-radius: 8px;
    padding: 10px 20px 20px;
    font-size: 14px;
    color: #8a8885;
    font-weight: 500;
  }
  .css-1pcexqc-containers{
    min-width: 267px !important;
    margin-left: -12px !important;
    position: relative !important;
    box-sizing: border-box !important;
    overflow: auto !important;
    max-height: 250px !important;
    box-shadow: 10px 10px 20px 0 rgb(0 0 0 / 20%) !important;
    z-index: 100007;
  }
 }