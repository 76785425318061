.dot-floating {
    position: relative;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #af762e;
    color: #af762e;
    animation: dot-floating 3s infinite cubic-bezier(0.55, 1.6, 0.3, 0.13);
   /* // animation: dot-floating 3s infinite cubic-bezier(0.15, 0.6, 0.9, 0.1); */
  }
  .dot-floating::before, .dot-floating::after {
    content: "";
    display: inline-block;
    position: absolute;
    top: 0;
  }
  .dot-floating::before {
    left: -12px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #af762e;
    color: #af762e;
    animation: dot-floating-before 3s infinite ease-in-out;
  }
  .dot-floating::after {
    left: -24px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #af762e;
    color: #af762e;
    animation: dot-floating-after 3s infinite cubic-bezier(0.4, 0, 1, 1);
  }
  
  @keyframes dot-floating {
    0% {
      left: calc(-50% - 5px);
    }
    75% {
      left: calc(50% + 105px);
    }
    100% {
      left: calc(50% + 105px);
    }
  }
  @keyframes dot-floating-before {
    0% {
      left: -50px;
    }
    50% {
      left: -12px;
    }
    75% {
      left: -50px;
    }
    100% {
      left: -50px;
    }
  }
  @keyframes dot-floating-after {
    0% {
      left: -100px;
    }
    50% {
      left: -24px;
    }
    75% {
      left: -100px;
    }
    100% {
      left: -100px;
    }
  }