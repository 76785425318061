.cart{
 
  height: 100%;
  width: 100%;
  color: rgb(206, 194, 194);
  z-index: 0;
  margin: auto;
margin-top: 8em;
  text-align: center;
  position: absolute;
    /* background-color: rgb(199, 60, 60); */
}
.cartcard{
    height: 11%;
  width: 80%;
  color: rgb(206, 194, 194);
  margin-top: 0.8%;
  border-radius: 1em;
  margin-left: 10%;
  text-align: center;
    /* background-color: rgb(201, 37, 37); */
        box-shadow: 15px 15px 15px #E4E9EA;
}
.splitcolumn{
    display: grid;
  grid-template-columns: 5% 55% 13% 10% 12% 3% ;
  background-color: #ffffff;
  padding: 0.4%;
  height: 100%;
  width: 100%;
  border-radius: 0.2em;

}
.splitcolumn > .cartcolumn1,.cartcolumn2,.cartcolumn3,.cartcolumn4 ,.cartcolumn5,.cartcolumn6{
    text-align: left;
    color: #333333
    ;
    padding-top: 0.2em;
    
  }
  .splitcolumn2{
    display: grid;
    grid-gap: 1px;
    grid-template-columns: repeat(1);
    /* background-color: #272f99; */
    height: 50%;
    width: 100%;
  }

  .splitcolumn2 > div {
      height: 100%;
    /* background-color: rgba(255, 255, 255, 0.8); */
}
.cartcolumn1{

}
.cartcolumn2{
    color: #738091;
    font-weight: 500;
    margin-left: 1em;
    margin-top: 0.5em;
    font-size: 1vw;
}
.cartcolumn3{
    font-size: 1vw;

    margin-left: 1em;
    margin-top: 0.8em;
}
.cartcolumn4{
    font-size: 1vw;

    margin-left: 1em;
    margin-top: 0.7em;
}   
.cartcolumn5{
    margin-top: 0.7em;

}
.cartcolumn6{
    margin-top: 0.6em;
margin-left: 1em;
}
.labelvalue{
    color: #333333
    ;
font-size: 0.9vw;
}
    .splitcolumn3{
        display: grid;
        grid-template-columns:  auto auto auto;
        /* background-color: #3e9927; */
        height: 50%;
        width: 100%;
      }
     
      .splitcolumn3 > div {
          /* background-color: chartreuse; */
      }
      .splitcolumn4{
        display: grid;
        grid-gap: 1px;
        grid-template-columns:  auto;
        /* background-color: #3e9927; */
        height: 50%;
        width: 100%;
      }
      .splitcolumn5{
        display: grid;
        grid-gap: 1px;
        grid-template-columns:  auto;
        /* background-color: #3e9927; */
        height: 50%;
        width: 100%;
      }
      .btnorder{
        background: #f8ecdd;
        /* padding:auto; */
        line-height: 16px;
        color: #656565;
        font-weight: 500;
        font-size: 14px;
        height: 50px;
        width: 120px;
        margin-left: 5px;
        border-radius: 5px;
        border: 1px solid transparent;

      }
