/* .MuiStepIcon-text{
    display: none;
} */
.MuiStepIcon-root.MuiStepIcon-active{
    color:rgb(175,119,45)!important;
}
.bottomSpacing{
    margin-bottom: 10px;
}
.MuiStepIcon-root.MuiStepIcon-completed{
    color:rgb(18,168,75)!important
}

.makeStyles-completed-7 {
    color:#fff!important;
    z-index: 1;
    font-size: 23px!important;
    border: 1px solid rgb(18,168,75);
    border-radius: 25px;
    background-color: rgb(18,168,75)!important;
}


/* .MuiStepper-root{
    width: 300px;
    margin-left: -30px;
} */


.makeStyles-circle-6 {
    width: 20px!important;
    height: 20px!important;
}
.makeStyles-active-5 {
    color: rgb(175,119,45)!important;
}
.MuiStepConnector-root .MuiStepConnector-horizontal .MuiStepConnector-alternativeLabel {
    background-color: rgb(18,168,75)!important;
}
.MuiStepLabel-alternativeLabel{
    color:#eaeaf0!important
}
.MuiStepLabel-active{
color:rgb(175,119,45)!important

}

.MuiStepLabel-completed{
    color: rgba(0, 0, 0, 0.87)!important
}


.makeStyles-prevButton-3{
    cursor: pointer!important;
}

.bottomDiv{
    width: 98%;
    display: flex;
    justify-content: space-between;
    margin-top: 29px;
    gap: 50%;
    /* bottom: 20px */
}


.cursor{
    cursor: pointer !important;
    padding-top: 30px !important;
}

#payment-demo-label{
    font-weight: 500!important;
  
}
 /* .MuiInputLabel-shrink {
    color:rgb(175,119,45)!important
} */

.MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input {
    padding: 6px 3px!important;
}


.completedd{
    width: 25px;
   height: 25px;
   border-radius: 50%;
   background: white;
   border: 5px solid grey;
   cursor: pointer;
   /* position: relative; */
   background: rgb(18,168,75);
   border: 5px solid rgb(18,168,75);
 }
 .completedd::after{
    content: '';
    /* position: absolute; */
    top: calc(50% - 5px);
    left: calc(50% - 6.5px);
    height: 5px;
    width: 12px;
    border-left: 2px solid white;
    border-bottom: 2px solid white;
    transform-origin: center;
    transform: rotate(-45deg);
    z-index: 2;
 }
 #outlined-adornment-amount:focus{
     border-color: rgb(175,119,45)!important;
 }
 .bottomDivResponsive{
    display: none !important;
 }
 .dropdownResponsive{
    display: none !important;
}
.PaymentDetailsResponsive{
    display: none !important;
}
.tbl_summaryHeaderResponsive{
    display: none !important;        
}
.TransactionBlockResponsive{
    display: none !important;
}
.FormLeftSIPResponsive{
    display: none !important;
}
.FormRightSIPResponsive{
    display: none !important;
}
.showDetailResponsive{
    display: none !important;
}
.footerTableResponsive{
    display: none !important;
}
.MultipleScheme{
    width:100% !important;
}
.footerTable{
    width:100% !important;
    /* margin-top: 150px !important */
}
.flexSpace{
    padding-right: 50px !important;
}
/* yogitha anded the style in stepper */
.MuiStepper-root{
    width: 100%;
    margin-left: -30px;
}
/* Mobile Responsive Created By Muthu Laskhmi K */
@media only screen and (min-width: 300px) and (max-width:350px)  {
    .paymentResponsive{
        width:280px;
    }
    .footerTableResponsive{
       width:305px !important;
       margin-left: -20px !important;
    }
    .MultipleScheme{
        margin-left: -10px;
    }
    /* .cursor{
        margin-left: -130px;
    } */
    .submitHeaderText{
        /* margin-left: 40px;*/
        width:300px; 
        /* margin-left: -20px; */
    }
    .faildHeaderText{
        display: flex;
        justify-content: center;
        text-align: center;
    }
    .faildText{
        /* width: 280px; */
        text-align: center;
        display: flex;
        justify-content: center;
    }
    .NameBlock{
        display: flex;
        justify-content: center;
        /* margin-left: 65px; */
    }
    .FolioBlock{
        display: flex;
        padding-bottom: 10px;
    }
    .FormLeftContent{   
        width:270px;
     }
     .Divider{
        margin-top: 3%;
        width: 250px; 
        /* margin-left:-20px !important; */
     }
     .orderID{
         display: flex;
         width:200px;
         margin-left: 80px;
     }
     .MuiStepper-root{
        width: 300px;
        /* margin-left: -50px; */
    }
    .multipleTab{
        width: 280px !important;
        margin-left: -10px !important;
     }
     .multipleTabs{
        width: 210px !important;
        margin-left: 30px !important;
     }
    .tableContent{
      margin-left: -3px;  
      margin-top: 15px; 
    }
    
}
@media only screen and (min-width: 350px) and (max-width:400px)  {
    .paymentResponsive{
        width:290px;
    }  
    /* .cursor{
        margin-left: -170px;
    } */
    .footerTableResponsive{
        width:345px !important;
        margin-left: -20px !important;
     }
     .MultipleScheme{
        margin-left: -10px;
    }
    .submitHeaderText{
        margin-left: 20px;
        width:300px;        
    }
    .faildHeaderText{
        display: flex;
        justify-content: center;
        text-align: center;
    }
    .faildText{
        /* width: 300px; */
        text-align: center;
        display: flex;
        justify-content: center;
    }
    .multipleTab{
        width: 310px !important;
        
    }
    .multipleTabs{
        width: 240px !important;
        margin-left: 30px !important;
     }
    .tableContent{
        margin-left: -3px;  
        width: 280px; 
    }
    .NameBlock{
        display: flex;
        margin-left: 80px;
    }
    .FolioBlock{
        display: flex;  
        padding-bottom: 10px;   
    }
    .FormLeftContent{   
        width:300px;
     }
     .Divider{
        margin-top: 3%;
        width: 280px; 
        /* margin-left:-20px !important; */
     }
     .orderID{
        display: flex;
        width:200px;
        margin-left: 80px;
    }
    /* .MuiStepper-root{
        width: 330px;
        margin-left: -35px;
    } */
    
}
@media only screen and (min-width: 400px) and (max-width:600px)  {
    .paymentResponsive{
        width:320px;
    }
    .footerTableResponsive{
        width:385px !important;
        margin-left: -20px !important;
     }
     .MultipleScheme{
        margin-left: -10px;
    }

    /* .cursor{
        margin-left: -200px;
    } */
    .Divider{
        margin-top: 3%;
        width: 320px; 
        /* margin-left:-20px !important; */
     }
     .submitHeaderText{
        margin-left: 40px;
        width:350px;
        
    }
    .multipleTab{
        width: 350px !important;   
    }
    .multipleTabs{
        width: 280px !important;
        margin-left: 30px !important;
     }
    /* .MuiStepper-root{
        width: 350px;
        margin-left: -30px;
    } */
    .faildHeaderText{
        display: flex;
        justify-content: center;
        text-align: center;
    }
    .faildText{
        text-align: center;
        display: flex;
        justify-content: center;
    }
    .NameBlock{
        display: flex;
        margin-left: 100px;
    }
    .FolioBlock{
        display: flex;     
        padding-bottom: 10px;
    }
    .FormLeftContent{   
        width:340px;
     }
     .orderID{
        display: flex;
        width:200px;
        margin-left: 80px;
    }
}

 @media only screen and (min-width: 300px) and (max-width:600px)  {
    .MainHeader, .SubHeader{
        /* margin-left: -20px !important; */
    }
    .bottomDiv{
       display: none !important;
    }
    .bottomDivResponsive{
        display: block !important;
     }
     .ResponsivePrevBtn{
         margin-top: 20px;
     }
     .showDetails{
        display: none !important;
     } 
     .showDetailResponsive{
         display: block !important;
     }
     .ResponsiveBtnNext{
        margin-top: 20px;
     }
     
     .dropdownBlock{
         display: none !important;
     }
     .dropdownResponsive{
         display: block !important;
     }
     .PaymentDetailsBlock{
         display: none !important;
     }
     .PaymentDetailsResponsive{
         display: block !important;
     }
     .footerTable{
         display: none;
     }
     
     .footerTableResponsive{
         display: block !important;
     }
     .paymentResponsive{
        background-color: rgb(250, 247, 242);
        margin-left: -20px;
        border-radius: 10px;
        padding-top: 10px;
     }
     .paymentContentBlock, .SummaryTableContent{
         display: flex;
     }
     .footerTableContent{
        display: flex;
        padding: 10px;
     }
     .footerTableContentLeft{
        flex: 70%;
        text-align: left;
     }
     .footerTableContentRight{
        flex: 30%;
        text-align: right !important;
        float:right !important;
     }
     .FormLeftContent{
        margin-top: 5px;
        display: flex;
        /* margin-left: -30px; */
     }
     .paymentContentLeft, .SummaryTableContentLeft, .FormLeftContentLeft{
        flex: 50%;
        /* padding: 10px; */
        margin-left: 10px;
        text-align: left;
     }

     .paymentContentRight,.SummaryTableContentRight, .FormLeftContentRight{
        flex: 50%;
        /* padding: 10px; */
        text-align: right !important;
        float:right !important;
     }
     .bottomPadding{
         padding: 0px !important;
     }
     
     .PaymentBank{
         text-align: left !important;
         margin-left: 10px;
     }
     .tbl_summaryHeader{
         display: none !important; 
         margin-top: -20px !important;      
     }
     .summaryTable{
         display: none !important;
     }
     .tbl_summaryHeaderResponsive{
        display: block !important;  
        padding: 0px !important;
        margin-top: 10px !important; 
        /* margin-left: -22  !important; */
        /* padding: 0px 30px 25px 30px;      */
        /* padding:'0px 30px 2px 30px', */
    }
    .TransactionBlock{
        display: none !important;
    }
    .TransactionBlockResponsive{
        display: block !important;
        height: 480px !important;
    }
    .FormLeftSIP{
        display: none !important;
    }
    .FormRightSIP{
        display: none !important;
    }
    .FormLeftSIPResponsive{
        display: block !important;
        /* margin-left: 20px; */
    }
    .FormRightSIPResponsive{
        display: block !important;
        margin-left: 20px;
    }
    .bottomDivider{
        display: none !important;
    }
    .bottomDividers{
        display: none !important;
        
    }
    .MuiTypography-body2{
        line-height: 1.43 !important;
    }
    .bottomDivCenter{
        margin-top: 60px !important;
    }
}
@media only screen and (min-width: 1000px) and (max-width:1400px)  {
    .flexSpace{
        padding-right:5px !important;
    }
}

@media only screen and (min-width: 1200px) and (max-width:1400px)  {
    .bottomDiv{
        width: 100% !important;
    }
    .bottomDividers{
        margin-left: 0px !important;
        /* margin-top: 7% !important; */
    }
}