/* .MuiListItem-button.Mui-selected:hover{
    background: #af772d !important;   
} */
/* #div_portfolio span:hover{
    background: url("../../assets/images/img7-3.png") no-repeat center top;
    display: inline-block;
    height: 24px;
    width: 80px;

} */
a{
    text-decoration: none;
    outline: none;
}
/* .navLeftFix{ position:fixed; top:50%; box-shadow: 0 3px 20px 0 rgba(19, 89, 161, 0.1);  z-index:22;margin-top:-120px}
.navLeftFix a{ width:80px; height:80px; display:block; text-align:center; background-color: #ffffff; border-bottom:solid 1px #e4e5e8;  color: #31455e; font-size:11px;  line-height: 1.36; padding-top:17px;}
.navLeftFix li{ position:relative}
.navLeftFix li:first-child a { border-radius:0 5px 0 0} 
.navLeftFix li:last-child a {  border-radius:0 0 5px 0; border-bottom:0} 

.navLeftFix a:hover{ background:#faf7f2; color:#af772d}
.navLeftFix a span{ display:block;   display:block; margin-bottom:8px; width:100%;}
.navLeftFix li.active>a{background:#af772d; color:#fff }

.navPor a span{ background:url(../../assets/images/img7.png) no-repeat center top; height:24px;}
.navPor a:hover span{ background:url(../../assets/images/img7-3.png) no-repeat center top;}
.navPor.active a span{ background:url(../../assets/images/img7-2.png) no-repeat center top;}

.navRep a span{ background:url(../../assets/images/img8.png) no-repeat center top; height:24px;}
.navRep a:hover span{ background:url(../../assets/images/img8-3.png) no-repeat center top;}
.navRep.active a span{ background:url(../../assets/images/img8-2.png) no-repeat center top;}

.navTra a span{ background:url(../../assets/images/img9.png) no-repeat center top; height:27px;}
.navTra a:hover span{ background:url(../../assets/images/img9-3.png) no-repeat center top;}
.navTra.active a span{ background:url(../../assets/images/img9-2.png) no-repeat center top;}

.navLeftFix li:hover .navDropBox{ display:block}
.navDropBox{ display:none; width:158px;  box-shadow: 7px 7px 20px 0 rgba(0, 0, 0, 0.2);  position:absolute; top:0; left:80px;}
.navDropBox a{ width:100%; height:59px; line-height:59px; padding:0; text-align:left; padding-left:30px; font-size:14px; background-color: #ffffff;}
.navDropBox a:first-child{ border-radius:5px 5px 0 0 !important }
.navDropBox a:last-child{ border-radius:0 0 5px 5px !important; border-bottom:0}
.navDropBox a:hover{  background-color: #faf7f2;}
	
@media (max-width:768px){
	.navLeftFix{ top:auto; bottom:0; left:50%; margin:0 0 0 -157px;}
	.navLeftFix li{ float:left;}
	.navLeftFix li:first-child a{     border-radius:  5px 0 0 0;}
	.navLeftFix li:last-child a{    border-radius: 0 5px 0 0;}
	.navDropBox{ top:auto; bottom:55px; left:-80px; z-index:9;}
	.navLeftFix a{ border-bottom:0;border-right: solid 1px #e4e5e8; height:53px; padding-top:15px; width:105px;}
	.navLeftFix a span{ width:26px; float:left; margin-left:15px; margin-bottom:0}
	.navLeftFix a b{ display:inline-block; line-height:25px; float: left; padding-left:7px;}
}

	.navDropBox a{ border-bottom:solid 1px #e4e5e8; height:auto; padding-top:0; line-height:50px; width:100%} */
/* #div_portfolio span{
    background: url("../../assets/images/img7.png") no-repeat center top;
    display: inline-block;
    height: 24px;
    width: 80px;
} */

/* #div_profile span{
    background: url("../../assets/images/img7.png") no-repeat center top;    
    display: inline-block;
    height: 27px;
    width: 50px;
}
#lnk_family span{
    background: url("../../assets/images/img8.png") no-repeat center top;
    display: inline-block;
    height: 27px;
    width: 70px;
}
#lnk_warning span{
    background: url("../../assets/images/img7.png") no-repeat center top;
    display: inline-block;
    height: 27px;
    width: 50px;
} */
/* #div_myplan span{
    background: url("../../assets/images/img8.png") no-repeat center top;
    display: inline-block;
    height: 27px;
    width: 70px;
}
#div_account span{
    background: url("../../assets/images/img9.png") no-repeat center top;
    display: inline-block;
    height: 27px;
    width: 70px;
   
}
#div_financial span{
    background: url("../../assets/images/img9.png") no-repeat center top;
    display: inline-block;
    height: 27px;
    width: 70px;
} */

/* .rc-menu__submenu > .rc-menu__item{
    padding:0em !important;
} */
/* .rc-menu__submenu{
    padding: 0.61em;
} */
/* .rc-menu__item{
    padding: 0em;
}
.rc-menu__item--hover {
background-color: #fff7f5;
color: #af772d;
}
.rc-menu{
    padding: 0em !important;
} */

/* #div_account{
    height: 50px;
    width: 70px;
} */
/* @media only screen and (min-width: 700px) and (max-width: 2560px) {
    .rc-menu--open.rc-menu--dir-bottom{
        margin-top: -71px;
        margin-left: 86px;
    }
    .rc-menu--closing.rc-menu--dir-bottom{
        margin-top: -71px;
        margin-left: 86px;
    }
    .rc-menu--open.rc-menu--dir-right{
        height: 364px;
        overflow-y: scroll;
    }
}

#div_reports span{
    background: url("../../assets/images/img8.png") no-repeat center top;   
    display: inline-block;
    height: 24px;
    width: 80px;
}

#popover_div:hover + #div_reports{
    background: #fff7f5 ;
    color: #af772d;
}

#div_transact span{
    background: url("../../assets/images/img9.png") no-repeat center top;
    display: inline-block;
    height: 27px;
    width: 80px;
} 

@media (max-width: 1024px) {
    #div_transact span{
        height: 27px !important;
        width: 60px !important;
    }

    #div_portfolio span{
        height: 24px !important;
        width: 60px !important;
    }

    #div_reports span{
        height: 24px !important;
        width: 60px !important;
    }
} */

/* 
.MuiListItem-button.Mui-selected:hover #div_transact span{
    background: url("../../assets/images/img9-2.png") no-repeat center top;
}

.MuiListItem-button.Mui-selected #div_transact span{
    background: url("../../assets/images/img9-2.png") no-repeat center top;
}

.MuiListItem-button:hover #div_transact span{
    background: url("../../assets/images/img9-3.png") no-repeat center top ;
}

.MuiListItem-button:hover #lnk_family span{
    background: url("../../assets/images/img8-3.png") no-repeat center top ;
} */
/* .MuiListItem-root.Mui-selected #lnk_family span{
    background: url("../../assets/images/img8-2.png") no-repeat center top ;
}
.MuiListItem-button:hover #lnk_warning span{
    background: url("../../assets/images/img7-3.png") no-repeat center top ;
}
.MuiListItem-root.Mui-selected #lnk_warning span{
    background: url("../../assets/images/img7-2.png") no-repeat center top ;
} */
/* .MuiListItem-button:hover #div_myplan span{
    background: url("../../assets/images/img8-3.png") no-repeat center top ;
} */
/* .MuiListItem-root.Mui-selected #div_myplan span{
    background: url("../../assets/images/img8-2.png") no-repeat center top ;
    
}
.MuiListItem-button:hover #div_financial span{
    background: url("../../assets/images/img9-3.png") no-repeat center top ;
}
.MuiListItem-root.Mui-selected #div_financial span{
    background: url("../../assets/images/img9-2.png") no-repeat center top ;
  
}
.MuiListItem-button:hover #div_account span{
    background: url("../../assets/images/img9-3.png") no-repeat center top ;
}
.MuiListItem-root.Mui-selected #div_account span{
    background: url("../../assets/images/img9-2.png") no-repeat center top ;
    
} */
/* .MuiListItem-button:hover #div_account span{
    background: url("../../assets/images/img9-3.png") no-repeat center top ;
} */

/* .MuiListItem-button:hover #div_profile span{
    background: url("../../assets/images/img7-3.png") no-repeat center top ;
}
.MuiListItem-button.Mui-selected #div_profile span{
    background: url("../../assets/images/img7-2.png") no-repeat center top;
}
.MuiListItem-button.Mui-selected:hover #div_portfolio span{
    background: url("../../assets/images/img7-2.png") no-repeat center top;
}

.MuiListItem-button.Mui-selected  #div_portfolio span{
    background: url("../../assets/images/img7-2.png") no-repeat center top;
}

.MuiListItem-button:hover #div_portfolio span{
    background: url("../../assets/images/img7-3.png") no-repeat center top ;
}


.MuiListItem-button.Mui-selected:hover #div_reports span{
    background: url("../../assets/images/img8-2.png") no-repeat center top;
}

.MuiListItem-button.Mui-selected  #div_reports span{
    background: url("../../assets/images/img8-2.png") no-repeat center top;
}

.MuiListItem-button:hover #div_reports span{
    background: url("../../assets/images/img8-3.png") no-repeat center top ;
} */

/* #first_menu{
    border-top-right-radius: 8px !important;
} */
/* #firstMenu{
    border-top-right-radius: 8px !important;   
}
#lastMenu{
    border-bottom-right-radius: 8px !important;    
}

#mouse-over-popover{
    z-index: 31 !important;
} */

@media only screen and (min-width: 320px) and (max-width: 600px) {
    /* .rc-menu--open.rc-menu--dir-bottom{
        margin-top: -140px;
        margin-left: revert;
    } */
    /* .rc-menu-container ul{ */
        /* left: -99.3125px;
        top: 58px; */
        /* display: flex;
        flex-direction: column;
    }
    #div_reports span{
        height: 24px !important;
        width: 40px !important;
    }
    #div_portfolio span{
        height: 24px !important;
        width: 40px !important;
    }
    #div_transact span{
        height: 27px !important;
        width: 40px !important;
    }
    .MuiListItem-gutters {
        padding-left: 5px !important;
        padding-right: 5px !important;
    } */
    
    /* .MuiListItem-gutters {
        padding-left: 0px;
        padding-right: 0px;
    }
    #lnk_portfolio{
        height: 30px !important;
        width: 70px !important;
    }
    #lnk_family{
        height: 30px !important;
        width: 70px !important;
    } */
}

@media only screen and (min-width: 350px) and (max-width: 400px) {
    /* .MuiListItem-button{
        width:110px !important;
        margin-left: 10px !important;
    } */
}
@media only screen and (min-width: 400px) and (max-width: 600px) {
    /* .MuiListItem-button{
        width:120px !important;
        margin-left: 10px !important;
    } */
}
@-moz-document url-prefix() {
    #sideTabScroll {
        scrollbar-width: thin !important;
    }
}
