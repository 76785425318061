

.multicheckbox {
    background-color: white !important;
    border-radius: 4px !important;
    margin-top: -40px !important;
    margin-left: -5px !important;
    position: absolute !important;
    z-index: none !important;
}
.cbSelect div .css-1r4vtzz span svg {
    color:grey;
    }
.placeholdercolor::placeholder {
	color: rgb(214, 214, 214);
    font-size: 28;
    font-weight: bold;
}
.css-1pcexqc-container {
    min-width: 15em;
    margin-right: 0px !important;
    position: relative;
    box-sizing: border-box;
    overflow: auto;
    max-height: 250px;
    box-shadow: 7px 7px 20px 0 rgba(0, 0, 0, 0.2) !important;
}

.css-11unzgr {
    max-height: none !important;
}

.css-48ayfv {
    padding: 8px 8px 5px 8px;
    background-color: #FFF;
    border-radius: 2px;
    border-width: 0;
    box-shadow: 0 0px 0px 0px rgba(0, 0, 0, 0.08) !important;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 1.36;
    -webkit-align-items: baseline;
    -webkit-box-align: baseline;
    -ms-flex-align: baseline;
    align-items: baseline;
    box-sizing: border-box;
    border-color: hsl(0, 0%, 80%);
    color: #515151;
    cursor: pointer;
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    margin: 0;
    margin-right: 0px;
    max-width: 100%;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    outline: currentcolor none medium !important;
    text-align: left;
    -webkit-text-decoration: none;
    text-decoration: none;
    -webkit-transition: background 0.1s ease-out 0s, box-shadow 0.15s cubic-bezier(0.47, 0.03, 0.49, 1.38) 0s;
    transition: background 0.1s ease-out 0s, box-shadow 0.15s cubic-bezier(0.47, 0.03, 0.49, 1.38) 0s;
    vertical-align: middle;
    white-space: nowrap;
    width: auto;
}

.css-1r4vtzz {
    padding: 8px 8px 5px 8px;
    background-color: #FFF;
    border-radius: 2px;
    /* border-width: '1px solid' !important; */
    box-shadow: 0 0px 0px 0px rgba(0, 0, 0, 0.08) !important;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 1.36;
    -webkit-align-items: baseline;
    -webkit-box-align: baseline;
    -ms-flex-align: baseline;
    align-items: baseline;
    box-sizing: border-box;
    border-color: hsl(0, 0%, 80%);
    color: #515151;
    cursor: pointer;
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    margin: 0;
    margin-right: 0px;
    /* max-width: 100%; */
    max-width: 110%;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    outline: currentcolor none medium !important;
    text-align: left;
    -webkit-text-decoration: none;
    text-decoration: none;
    -webkit-transition: background 0.1s ease-out 0s, box-shadow 0.15s cubic-bezier(0.47, 0.03, 0.49, 1.38) 0s;
    transition: background 0.1s ease-out 0s, box-shadow 0.15s cubic-bezier(0.47, 0.03, 0.49, 1.38) 0s;
    vertical-align: middle;
    white-space: nowrap;
    width: auto;
}

.css-1qprcsu-option {
    background-color:white !important;
    cursor: default;
    display: block;
    font-size: inherit;

    /* color: #AF772D !important; */

    padding: 8px 9px;
    width: 100%;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    box-sizing: border-box;
    min-width: 240px;
}

.css-ik6y5r {
    box-shadow: inset 0 1px 0 rgba(0, 0, 0, 0.1);
    text-align: left !important;
}

/* .MuiInputBase-input {
    font: inherit;
    color: currentColor;
    max-width: 100%;
    border: 0;
    height: 1.1876em;
    margin: 0;
    display: block;
    padding: 6px 0 7px 7px !important;
    min-width: 0;
    background: none;
    box-sizing: content-box;
    margin-left: -2% !important;
    animation-name: mui-auto-fill-cancel;
    letter-spacing: inherit;
    animation-duration: 10ms;
    -webkit-tap-highlight-color: transparent;
} */

.css-1vr111p-option:hover {
    background-color: #FDFDFD;
}

.sortbylist:hover {
    background: #faf7f2 !important;
    color: #af772d;
    border-radius: 10%;
}


/* .css-11unzgr {
    overflow: hidden !important;
    max-height: 500em !important;
 }
 
 .css-ik6y5r {
   overflow: hidden !important;
   max-height: 8em;
  } */

.schemeSoftByPopover {
    width: 120px; 
    z-index: 0;
    /* Modified 158 -110 by Celsia */
}

.MuiListItemText-root>span {
    padding-bottom: 0px !important;
}

.categorySpan+div {
    position: relative;
    width: 92%;
}

@media (max-width: 1024px) {
    .categorySpan+div {
        position: relative;
        width: 68%;
    }
}

@media (max-width: 1080px) {
    .categorySpan+div {
        position: relative;
        width: 68%;
    }
}

@media (max-width: 1180px) {
    .categorySpan+div {
        position: relative;
        width: 68%;
    }
}

.css-ik6y5r {
    /* box-shadow: 7px 7px 20px 0 rgba(0, 0, 0, 0.2); */
    overflow-y: none !important;
    max-height: none !important;
}

.css-1v99tuv {
    /* font-size: 16px !important; */
    font-weight: bold !important;
}

.css-1qprcsu-option {
    color:inherit;
    cursor: default;
    font-size: inherit;
    font-weight: lighter !important;
}

.css-1vr111p-option {
    color: #333 !important;
    cursor: default;
    font-size: inherit;
    width: 261;
    font-weight: 300 !important;
}

.css-1vr111p-option:hover {
    background-color: white !important;
}

.filterpop {
    position: absolute !important;
    z-index: 1 !important;
}

.css-1vr111p-option>input[type=checkbox] {
    width: 20px;
    margin-right: 15px!important;
    height: 12px;
    -webkit-appearance: none;
    -o-appearance: none;
    appearance: none;
    margin: 1px;
    /* outline: 1px solid #af772d; */
    box-shadow: none;
    font-size: 2em;
    border: #af772d 0px solid !important;
    border-radius: 3px !important;
}


/* .css-1vr111p-option > input[type="checkbox"]:checked  {
    background: #ffff;
    color: #af772d;
    border-color: #af772d;
    -webkit-appearance: none;
    -moz-appearance: none;
    -o-appearance: none;
    appearance: none;
  } */

.css-1vr111p-option>input[type=checkbox]:before {
    content: "";
    display: block;
    width: 14px;
    height: 14px;
    top: 0px;
    left: 0px;
    border: 1px solid #c3c2c2;
    border-radius: 3px;
    background-color: white;
}

/* .css-1qprcsu-option>input[type=checkbox]{
    width: 20px;
    margin-right: 15px!important;
}

.css-1qprcsu-option>input[type=checkbox]::after {
    background-image: url('../../../../assets/images/checkboxtick.png');
    background-repeat: no-repeat;
    background-position: center;
    content: "";
    margin-right: 15px !important;
    display: block;
    width: 16px;
    height: 14px;
    top: 0px;
    left: 0px;
    border: 1px solid #af772d;
    border-radius: 3px;
    background-color: white;
    background-size: 10px;
    
} */

.css-6y1x9t-control {
    border:none !important;
    box-shadow: none !important;
    /* border-color: #af772d !important;
    box-shadow: 0 0 0 0.1px #af772d !important; */
}
.css-6y1x9t-control::after {
    border:none !important;
    box-shadow: none;
}
.css-6y1x9t-control:hover{
    border:none !important;
    box-shadow: none !important;
}
.css-1hwfws3{
    border:none !important;
    box-shadow: none;
}
.css-1vr111p-option>input[type="checkbox"]:checked {
    color: red !important;
    transform: scale(1.25);
}
        

/* .css-1vr111p-option > input[type="checkbox"]:checked:after {
    position: relative;
    display: block;
    left: 2px;
    top: -11px;
    width: 7px;
    height: 7px;
    border-width: 1px;
    border-style: solid;
    border-color: #B3B3B3 #dcddde #dcddde #B3B3B3;
    content: "";
    background-image: linear-gradient(135deg, #B1B6BE 0%, #FFF 100%);
    background-repeat: no-repeat;
    background-position: center;
  } */


/* .css-1vr111p-option > input[type="checkbox"]:checked :after {
    left: 3px;
    top: 3px;
    width: 11px;
    height: 10px;
    border: 0;
    border-width: 0;
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
}
 */

.multicheckbox {
    z-index: 1 !important;
}

.cbSelectOne>div {
    z-index: 1 !important;
}

.cbSelect>div {
    z-index: 1 !important;
}


/* .filterAvailableToInvestCross{
    z-index: 0 !important;
} */


/* .cbSelectOne  .css-48ayfv > div{
    z-index: 1 !important;
} */


.MuiAvatar-root.MuiAvatar-square.makeStyles-square-282.MuiAvatar-colorDefault {
    margin-top: 8px !important;
  }
  /* .MuiAvatar-square {
    margin-top: -3px !important;
} */
/* .cbSelect div:first-child  {
    z-index: 1 !important;
  } */

  /* Added by Celsia on 25 May 2021 for Mobile Responsive */
 @media only screen and (min-width: 320px) and (max-width: 374px) {
    /* .css-1r4vtzz {
        min-width: 141%!important;
    } */
    .cbSelectOne>div {
        z-index: 0 !important;
    }
    .css-1pcexqc-container {
        min-width: 20em;
    }

  }  
  @media only screen and (min-width: 375px) and (max-width: 424px) {
    /* .css-1r4vtzz {
        min-width: 171%!important;
    } */
    .cbSelectOne>div {
        z-index: 0 !important;
    }
    .css-1pcexqc-container {
        min-width: 24em;
    }
    
  } 
  @media only screen and (min-width: 425px) and (max-width: 600px) {
    /* .css-1r4vtzz {
        min-width: 198%!important;
    } */
   
    .cbSelectOne>div {
        z-index: 0 !important;
    }
    .css-1pcexqc-container {
        min-width: 27em;
    }
  } 

  
  /* End */
  
