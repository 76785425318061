.progressVisualFull {
    display: flex;
    /* change the value if you want different thickness */
    height: 6px;
    margin-left: 10px;
    margin-right: 10px;
    /* margin: 20px 0; */
  }
  
  .progressVisualPart {
    /* Number of the seconds for complete animation */
    transition: width 2s;
  }
  
  .progressLabel {
    /* Nothing here, move along */
    /* font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
      "Lucida Sans Unicode", Geneva, Verdana, sans-serif; */
      font-size: 13px;
      /* padding-left: 10px; */
      text-align: left;
  }
  .multicolor-bar {
    /* margin: 20px 20%; */
  }
  
  .multicolor-bar .values .value {
    float: left;
    text-align: center;
  }
  
  .multicolor-bar .scale .graduation {
    float: left;
    text-align: center;
  }
  
  .multicolor-bar .bars .bar {
    float: left;
    height: 10px;
  }
  
  .multicolor-bar .bars div.bar:first-of-type {
      border-top-left-radius: 2px;
      border-bottom-left-radius: 2px;
  }
  
  .multicolor-bar .bars div.bar:last-of-type {
      border-top-right-radius: 2px;
      border-bottom-right-radius: 2px;
  }
  
  .multicolor-bar .legends {
    text-align: center;
  }
  
  
  .multicolor-bar .legends .legend {
      display: inline-block;
      margin: 0 5px;
      text-align: center;
  }
  
  .multicolor-bar .legends .legend .dot {
    font-size: 15px;
    vertical-align: middle;
  }
  
  .multicolor-bar .legends .legend .label {
    margin-left: 2px;
    vertical-align: middle;
  }
/*   
  body {
    margin: 20px;
    padding: 20px;
    border: 1px solid #e5e5e5;
    border-radius: 6px;
  }
   */