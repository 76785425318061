.switchContainer{
    width:176px;
    background-color: #e7eaec;
    height: 40px;
    border-radius: 20px;
    display: flex;
    justify-content: space-evenly;
font-size:14px;

 box-shadow: 0 3px 1px 0 rgba(0, 0, 0, 0.06);

 border: solid 0.5px rgba(189, 189, 189, 0.04);
}

.switchContainer label{
    color: #31455e;
    text-align: center;
    padding: 4px;
    padding-top: 10px;
    margin-top: 2px;
    margin-left: 2px;
    margin-right: 2px;
    cursor: pointer;
}

.switchContainer  .active{
    color: #af772d;;
    background-color: #ffffff!important;
    width:176px;
    border-radius: 20px;
}

.switcherLabel{
    width:176px;
    cursor: pointer;
}
