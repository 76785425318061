

.summaryCategoryChart {
    display: none;
}

.pull-right {
    display: flex;
    justify-content: flex-end;
}

.h1 {
    margin-top: 10px !important;
    margin-bottom: 20px !important;
}

.container {
    padding: 20px;
    text-align: center;
}

/* ----- For Carousal styles --- */
#carousal-desktopResponsive .xzcEF {
    display: none !important;
}
#carousal-mobileResponsive .xzcEF {
    display: none !important;
}
/* ---- End ----- */
s.barChartShowMore {
    border: solid 1px #31455e;
    width: 113px;
    height: 21px;
    font-size: 14px;
    padding: 5px;
    color: #31455e;
    border-radius: 20px;
}

.barChartShowMore:hover {
    color: #fff;
    background-color: #31455e;
    cursor: pointer;
}

.btn {
    display: inline-block;
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: 1px solid transparent;
    padding: .375rem .75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: .25rem;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.vl {
    border-left: 1px solid rgb(247, 242, 242);
    padding-left: 22px;
    border-right: 1px solid rgb(247, 242, 242);
    padding-right: 22px;
}

.switcherDonutLabel {
    width: 33.33%;
    float: left;
    padding: 0;
    margin: 0;
    font-size: 14px;
    color: #8b939b;
    border-right: solid 1px #e7eaec;
}

.summaryHeader {
    text-align: left;
    font-size: 18px;
    /* width: 316px !important; */
}
.summaryChartHeader{
    text-align: left;
    font-size: 18px;
    width: 316px !important; 
    /* modified by yogitha  */
    /* margin: 0% 0% 0% 4%;  */
    /* end */
}
#cardsDisplay {
    display: none;
}

@media only screen and (max-width: 350px) {
    /* .summaryCategory {
        display: none;
        padding-right: 20px;
    } */
    .summaryCategoryHeader {
        text-align: center !important;
        font-size: 18px;
    }
    .summaryEquityHeader {
        text-align: center !important;
        font-size: 18px;
        margin-left: 20px;
        width: 280px;
    }
    .chart {
        /* width: 100%; */
        height: 250px;
        margin:0 0px 0px 15px;
        /* margin-top: -50px; */
        /* margin: -40px 10px; */
        /* margin-left: -20px; */
        /* background-color: red; */
    }
    .summaryCategoryChart {
        display: block;
    }
    .DoubleDonutChart {
        /* margin-top: 30px !important; */
        padding-bottom: 30px !important;
        height: auto !important;
    }
    .DoubleDonutChart,
    .EFBarchart {
        box-shadow: 0 0 5px 0 rgb(19 89 161 / 6%);
        border: solid 1px #ecf3fc;
        background-color: #ffffff;
        padding: 0;
        border-radius: 8px;
        margin-bottom: 2px;
        /* width: 330px; */
        /* margin-left: -30px; */
    }
    #cardDisplay {
        display: none;
    }
    #cardsDisplay {
        display: block;
    }
    .SwitcherValue {
        flex: 1;
        flex-direction: 'column';
        margin-left: 15px;
    }
    /* .cardData{
    width:100px;
  } */
    .CategoryChat {
        height: 280px;
    }
}

@media only screen and (min-width:360px) and (max-width: 400px) {
    .summaryCategoryHeader {
        padding-right: 40px !important;
    }
    /* .DonutChart{
    padding-right: 30px !important;
  } */
}

@media only screen and (min-width:400px) and (max-width: 420px) {
    .summaryCategoryHeader {
        /* padding-right: 40px !important; */
    }
    .DonutChart {
        /* padding-right: 50px !important;  */
    }
}
@media only screen and (min-width:600px) and (max-width: 900px) {
    #carousal-desktopResponsive .rec-swipable{
        display: flex;
        flex-direction: row;
        gap: 5px;
    }

}
@media only screen and (min-width:350px) and (max-width: 400px) {
    /* .summaryCategory {
        display: none;
        padding-right: 20px;
    } */
    .summaryCategoryHeader {
        text-align: center !important;
        font-size: 18px;
        padding-left: 50px;
    }
    .chart {
        /* width: 300px; */
        height: 300px;
        margin:0 0px 0px 15px;
        /* padding-left: 30px; */
        /* margin-left: -30px; */
        /* margin-top: -50px; */
        /* margin: -40px 25px; */
    }
    .summaryCategoryChart {
        display: block;
    }
    .DoubleDonutChart {
        /* width:400px; */
        /* margin-left: -35px; */
        padding-bottom: 30px;
        /* height: 600px; */
    }
    .EFBarchart {
        /* width:400px; */
        /* margin-left: -35px; */
    }
    /* .summaryCategoryHeader{
     margin-right: 50px;
   } */
    /* #cardsDisplay{
    display: block;
  }
  #cardDisplay{
    display: none;
  } */
    .SwitcherValue {
        flex: 1;
        flex-direction: 'column';
        margin-left: 35px !important;
    }
}

@media only screen and (min-width:400px) and (max-width:500px) {
    .DoubleDonutChart {
        /* width:430px; */
        /* margin-left: -40px; */
        /* padding-bottom: 50px; */
        padding-bottom: 30px;
    }
    .EFBarchart {
        /* width:430px; */
        /* margin-left: -40px; */
    }
    .chart {
        height: 300px;
        margin: 0px 50px;
        /* margin: 0% 5% 0% 5%; */
    }
}

@media only screen and (min-width:300px) and (max-width: 600px) {
    #cardDisplay {
        display: none;
    }
    #cardsDisplay {
        display: block;
    }
}

@media only screen and (max-width:800px) {
    /* .summaryCategory {
        display: none;
        padding-right: 20px;
    } */
    .summaryCategoryHeader {
        text-align: center !important;
        font-size: 18px;
    }
    .summaryCategoryChart {
        display: block;
    }
    .DoubleDonutChart,
    .EFBarchart {
        box-shadow: 0 0 5px 0 rgb(19 89 161 / 6%);
        border: solid 1px #ecf3fc;
        background-color: #ffffff;
        padding: 0;
        border-radius: 8px;
        margin-bottom: 2px;
        margin-top: 20px;
        margin: 0px 10px;
    }
    .DoubleDonutChart {
        /* height: 600px; */
        padding-bottom: 30px;
    }
}


/* @media only screen and (min-width: 350px) and (max-width: 400px)  {
  .summaryCategory{
    display: none;
    padding-right: 20px;
  }
  .summaryCategoryHeader{
    text-align:center !important;
    font-size:18px;
  }
  .chart{
     width:300px;
     height: 350px;
     margin-left: 10px;
   }
   .summaryCategoryChart{
     display: block;
   }
} */

.desktopResponsive {
    display: block;
}

.mobileResponsive {
    display: none;
}


/* styles added by yogitha for mobile responsive on 05/06/2021 */

@media only screen and (min-width:320px) and (max-width: 600px) {
    .desktopResponsive {
        display: none;
    }
    .mobileResponsive {
        display: block;
    }
    .gridContainerForm {
        display: grid;
        grid-template-columns: repeat(2, 50%);
        grid-column-gap: 10px;
    }
    .TreeTableHeight {
        height: auto !important;
        margin-bottom: 27px;
    }
}

.switcherListValue {
    font-size: 16px;
    text-align: right;
    color: #010708;
    font-weight: bold;
}
.switcherSingleValue{
    font-size: 16px;
    text-align: left;
    color: #010708;
    font-weight: bold;
    width: 150px;
    margin-left: 30px;
}

.reportResponsive{
    display: none !important;
}
@media only screen and (min-width: 300px) and (max-width:600px) {
    .reportResponsive{
        display: block !important;
    }
    .reportDesign{
        display: none !important;
    }
}

@media only screen and (min-width: 600px) and (max-width:1024px) {
    .TreeTableHeight {
        margin-top: 10em !important;
    }
}

.switcherListHeader {
    /* white-space: nowrap; */
    font-size: 14px;
    color: #8b939b;
    width: 90%;
    text-align: left;
    float: right;
    text-indent: 4%;
}

.switcherListValue {
    font-size: 16px;
    text-align: right;
    color: #010708;
    font-weight: bold;
}

.switcherListValue1 {
    font-size: 16px;
    text-align: right;
    color: #010708;
    font-weight: bold;
}
.downloadImage {
    height: 34px;
    width: 34px;
    cursor: pointer;
}

.bullertchartcontainer {
    /* display: grid;
  grid-template-columns: repeat(3,1fr); */
    width: 25%;
    margin-top: 15%;
}

.bulletPointContainer {
    text-align: right;
    width: 10%;
    float: left;
    display: flex;
}

.bulletValueBox {
    /* display: grid; */
    display: "flex";
    flex: 100%;
    flex-direction: column;
}

.bulletAndValueDiv {
    display: 'flex';
    flex-direction: row;
    flex: 100%;
}

.mycard {
    border-bottom: 1px solid;
    margin-top: 2%;
}

@media only screen and (min-width: 320px) and (max-width:600px) {
    /* .bulletPointContainer{
    width: 100%;
  } */
    .bulletAndValueDiv {
        display: 'flex';
        flex-direction: row;
        flex: unset;
    }
    .bullertchartcontainer {
        width: 100% !important;
    }
    .bulletValueBox {
        flex: 100% 1;
        display: flex;
        flex-direction: row-reverse;
    }
    .switcherListValue {
        font-size: 14px;
        text-align: right;
        color: #010708;
        font-weight: bold;
        width: 100%;
        display: flex;
        flex-direction: row;
        /* justify-content: flex-end; */
        margin-left: 25px;
    }
    .switcherListValue1 {
        font-size: 14px;
        text-align: left;
        color: #010708;
        font-weight: bold;
        width: 100%;
        display: flex;
        flex-direction: row;
        /* justify-content: flex-end; */
        margin-left: 25px;
    }
    .bullertchartcontainer {
        width: 25%;
        margin-top: 10%;
    }
    /* .bulletPointContainerBlock:first-child {
        margin-left: 10%;
        text-align: left;
        padding-right: 8px !important;
        margin-top: 4%;
       
    } */
}


/* @media only screen and (max-width: 2560px) {
  .downloadImage
  {
    position:absolute;
  height:34px;
  width:34px;
  top:53%;
  left:28%;
  cursor: pointer;

  }
}
@media only screen and (max-width: 1366px) {
  .downloadImage
  {
    position:absolute;
  height:34px;
  width:34px;
  top:47%;
  left:40%;
  cursor: pointer;

  }
}
@media only screen and (max-width: 1440px) {
  .downloadImage
  {
    position:absolute;
  height:34px;
  width:34px;
  top:46%;
  left:41%;
  cursor: pointer;
  }
}
@media only screen and (max-width: 1024px) {
  .downloadImage
  {
    position:absolute;
  height:34px;
  width:34px;
  top:48%;
  left:52%;
  cursor: pointer;

  }
}  */

.bulletPointContainerBlock{
    width: 11px;
}
.proTxtBig {
    position: relative;
    padding-left: 28px;
    font-weight: bold;
}


  .bulletPointContainerBlock:first-child {
        text-align: left;
        padding-right: 8px;
        margin-top: 2%;
    }

.proTxtBig::before {
    content: '';
    display: block;
    left: 0;
    /* background: #fff; */
    position: absolute;
    width: 16px;
    height: 16px;
    /* border: solid 1px #f66d44; */
    word-break: break-word;

}
.proTxtBig::after {
    content: '';
    width: 10px;
    height: 10px;
    /* background: #f66d44; */
    position: absolute;
    left: 3px;
    top: 3px;
    word-break: break-word;
}
@-moz-document url-prefix() {
    #scroll_responsive{
    scrollbar-width: thin !important;
    /* background-color:#c7c7c7; */
    /* border-radius: 10px; */
}
}