/* .MuiDialog-paperWidthSm{
max-width: 100% !important;
} */
/* .MuiAutocomplete-option{
background-color: white !important;
box-shadow: 0 0 10px 0 rgba(19, 89, 161, 0.3);
} */
.contentcolumnProfile{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 14px;
    /* padding: 14px;
  */
  padding-top: 14px;
    flex:1;
    text-align: left;
}
.contentCountryProfile{
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 14px;
  padding-top: 10px;
  padding-bottom: 10px;
  flex:1;
  text-align: left;
  background-color:#f7f9fc;
  margin: 0% -3.5%;
  padding-left: 24px;
  padding-right: 24px;
}

.contentcolumnProfile4col{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 1px;
    padding: 14px;
    flex:1;
    text-align: left;
}
.contentcolumnProfileRowColumn{
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 14px;
  padding-top: 14px;
  flex:1;
  text-align: left;
}
.desktopResponsive {
  display: block;
}
.mobileResponsive {
  display: none;
}
/* styles added by yogitha for mobile responsive on 05/06/2021 */
@media only screen and (min-width:320px) and (max-width: 600px) {
  .desktopResponsive {
    display: none;
}
.mobileResponsive {
    display: block;
    margin-left: 0px !important;
}
  .contentcolumnProfile{
    display: grid;
    grid-template-columns: repeat(2, 50%);
    grid-gap: 20px;
  padding-top: 14px;
    flex:1;
    text-align: left;
}
.contentCountryProfile{
  display: grid;
  grid-template-columns: repeat(2, 50%);
  grid-gap: 20px;
padding-top: 14px;
  flex:1;
  text-align: left;
  background-color:#f7f9fc;
  margin: 0% -3.5%;
  padding-left: 24px;
  padding-right: 24px;
}
.contentcolumnProfileRowColumn{
  display: grid;
  grid-template-columns: repeat(2, 50%);  
  grid-gap: 20px;
padding-top: 14px;
  flex:1;
  text-align: left;
  white-space: nowrap;
}
}

.css-1wa3eu0-placeholder{
  font-size: 14px !important;
}
.css-1uccc91-singleValue{
  font-size: 14px !important; 
}

/* .MuiDialog-container{
  background:rgba(0,0,0,0.5);
  opacity: 0;
} */
.MuiDialogActions-root {
  background:transparent !important;
}