.columntemp{
    display: grid;
    grid-template-columns: repeat(10, 1fr);
    /* grid-gap: 1px; */
    padding: 0px 14px 0px 14px;
    font-size:12px ;
    font-weight: 500; 
    flex:1;
    text-align: left;
}
.columntemp1{
    display: grid;
    grid-template-columns: repeat(9, 0.5fr);
    /* grid-gap: 1px; */
    /* padding: 14px; */
    padding-left:14px;
    font-size:13px ;
    font-weight: 500; 
    flex:1;
    text-align: left;
}
.amount{
    text-align: right;
    /* width: 100px; */
}
.subheading{
    /* text-align: right;
text-transform: uppercase;
color: #808080;
padding-top: 28px; */
}
.afrcard{
    background-color:#f7f2f200;
    color:#31455E ;
    border-radius: 5px;
    box-shadow:0px 1px 2px 1px #e0e5eb;
    display: "flex";
    font-size: 13px !important;
   flex-direction: "row";
   /* padding-top: 42px;
   height: 48px; */
   align-items: center;
height: 73px;
/* margin-right: 1.5%; */
}
.amountexit{
    text-align: right;
    width: 60px;
}
.subheadingexit{
    /* text-align: right;
text-transform: uppercase; */
width: 60px;
/* color: #808080; */
}
.heading{
    text-transform: uppercase;
    color: #808080;
    font-size: 15;
}
.columntemp2{
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    /* grid-gap: 1px; */
    padding-left: 14px;
    align-items: center;
    font-size:12px ;
    font-weight: 500; 
    flex:1;
    text-align: left;
}
.subheadingelss{
    /* text-align: right;
text-transform: uppercase;
color: #808080;  */
}
.amountelss, .invested{
    text-align: right;
    /* width:70px !important */
}
.amountElss{
    text-align: right;
    width:90px !important
}
.presentValue{
    width: 93px;
    text-align: right;
}
.HeaderBlockResponsive{
    display: none !important;
    width:100%;
    
}

/* Responsive Design Created By Muthu Lakshmi K */

@media only screen and (max-width: 600px)   {
    .STLTHeader,.ExitHeader{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        width: 100%;
        padding-top: 10px;
        text-align: left;
        padding-bottom: 10px;
        font-size: 12px;
        color: #808080;
        font-weight: 500;
    }
     
    .SchemeName{
        font-size: 14px !important;
        font-weight: bold;
    }
    #countScheme{
        text-align: left;
        color: rgb(167, 167, 167);
        font-size: 14px;
    }
    .StltHeader{
        text-align: left;
        color: rgb(167, 167, 167);
        font-size: 12px;
    }
    .STLTHeaderLeft,.ExitHeaderLeft{
        display: flex;
        flex-direction: column;
        flex-basis: 200%;
        flex: 1;
        margin-left: 10px;
    }   
    .STLTHeaderRight,.ExitHeaderRight{
        
        margin-right: 10px;
        text-align: right;
    }
    .contentHeaderBlock{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        width: 100%;
        padding-top: 10px;
        text-align: left;
        padding-bottom: 10px;
    }
    
    .contentHeaderLeft{
        display: flex;
        flex-direction: column;
        flex-basis: 200%;
        flex: 1;
        margin-left: 10px;
       
      }
      
      .contentHeaderRight{
          margin-right: 10px;
      }
      .value{
          color: #31455E;
          font-weight: 500;
         font-size: 14px;
      }
      .contentSTLTBlock, .contentExitBlock,.contentELSSBlock{
        box-shadow: 0 0 5px 0 rgb(19 89 161 / 6%);
        border: solid 1px #ecf3fc;
        background-color: #ffffff; 
        padding: 5px 0;
        border-radius: 8px;
        margin-bottom: 2px;
      }
      .STLTBlocks, .STLTfooter, .ExitBlocks, .ELSSBlocks{
        display: flex;
    }
    .contentSTLTLeft, .STLTfooterLeft,.contentExitLeft, .contentELSSLeft{
        flex: 50%;
        padding: 10px;
        text-align: left;
      }
      
      .contentSTLTRight, .STLTfooterRight, .contentExitRight, .contentELSSRight{
        flex: 50%;
        padding: 10px;
        text-align: right !important;
        /* width:130px; */
        float:right !important;
      }
      #SchemeName{
        font-size: 14px;
        font-weight: 500;
        text-align: left;
        min-width: 180px;
      }
      #ExitSchemeName{
        font-size: 12px;
        font-weight: bold;
        text-align: left;
        min-width: 180px;
      }
      .footerSTLTBlock{      
        /* min-width: 250px !important; */
        width: 100%;
        border-radius: 6px;
        background-color: #3D4348;
        margin-left: 1px;
        margin-right: 5px;
    }
    .footerText{
        font-size: 16px;
        font-weight: bold;
    }
    .HeaderBlockResponsive{
        display: block !important;     
    }
}


@media only screen and (max-width: 350px){
    #STLTResponsive, #ELSSResponsive{
        margin-top:30px;
        /* width:300px; */
        /* margin-left:-15px; */
    }
}
@media only screen and (min-width: 350px) and (max-width:400px)  {
    #STLTResponsive, #ELSSResponsive{
        margin-top:30px;
        /* width:350px; */
        /* margin-left:-15px; */
    }
    
}
@media only screen and (min-width: 400px) and (max-width:600px)  {
    
    #STLTResponsive, #ELSSResponsive{
        margin-top:30px;
        /* width:380px; */
        /* margin-left:-15px; */
    }
}

@media only screen and (min-width: 1250px)  and (max-width: 1300px) {
    .amountelss{
        text-align: right;
        width:70px !important;
    }
    .presentValue{
        width: 70px;
        text-align: right;
    }
    .invested{
        text-align: right;
        width:110px !important;
    }
    #headerAlign{
        margin-left: 10px;
    }
}

@media only screen and (min-width: 1000px) and (max-width:1030px){
    .invested{
        text-align: right;
        width:70px !important;
        margin-left: 80px;
    }
    .presentValue{
        width: 70px;
        text-align: right;
        margin-right: 50px;
    }
}