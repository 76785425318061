#popup-tab{
    display: block !important;
    color: #656565 !important;
    font-size: 12px;
}

/* #user-option{
    border-top: solid 1px #e4e5e8;
    border-bottom: solid 1px #e4e5e8;
} */
/* #user-name{
    color: #333333;
    font-weight: 500;
    font-size: 12px;   
} */
@-moz-document url-prefix() {
    #rightMenuAlign {
        width: 575px !important;
    }
}

