.portfolioDetailsResponsive{
    display: none !important;
}
.portfolioDetails{
    margin-left: 20px;
}
/* max-width changed to 928 from 600 by dulcy*/
@media only screen and (min-width: 300px) and (max-width:928px)   {
    .portfolioDetails{
        display: none !important;
    }
    .portfolioDetailsResponsive{
        display: block !important;
    }
    .portfolioSchemeBlock, .portfolioDataBlock{
        display: flex;
    }
    .portfolioSchemeLeft, .portfolioDataLeft{
        flex: 50%;
        /* padding: 10px; */
        padding-left: 10px;
        padding-right: 10px;
        padding-top: 5px;
        text-align: left;
    }
    .portfolioTxnLeft{
        flex: 80%;
        /* padding: 10px; */
        padding-left: 10px;
        padding-right: 10px;
        padding-top: 5px;
        text-align: left;
    }
    .portfolioSchemeRight, .portfolioDataRight{
        flex: 50%;
        /* padding: 10px; */
        padding-left: 10px;
        padding-right: 10px;
        padding-top: 5px;
        text-align: right !important;
        /* width:130px; */
        float:right !important;
    }
    .portfolioDataRightAlign{
        flex: 70%;
        /* padding: 10px; */
        padding-left: 10px;
        padding-right: 10px;
        padding-top: 5px;
        text-align: right !important;
        /* width:130px; */
        float:right !important;   
    }
    .portfolioReport{
        box-shadow: 0 0 5px 0 rgb(19 89 161 / 6%);
        background-color: rgb(250, 250, 250);
        border: solid 1px #ecf3fc;
        padding: 15px 0;
        border-radius: 8px;
        margin-bottom: 2px;
    }
    .portfolioReportData{
        box-shadow: 0 0 5px 0 rgb(19 89 161 / 6%);
        border: solid 1px #ecf3fc;
        background-color: #ffffff; 
        padding: 15px 0;
        border-radius: 8px;
        margin-bottom: 2px;
    }
    .portfolioHeader{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        width: 100%;
        padding-top: 30px;
        text-align: left;
        /* padding-bottom: 10px; */
        font-size: 14px;
        color: #808080;
        font-weight: 500;
    }
    .portfolioHeaderLeft{
        display: flex;
        flex-direction: column;
        flex-basis: 150%;
        flex: 1;
        margin-left: 20px;
    }
    .portfolioHeaderRight{
        margin-right: 14px;
        text-align: right;
    }
    .portfolioHeaderDesign{
        /* text-align: left; */
        color: rgb(167, 167, 167);
        font-size: 14px;
    }
    .schemeName{
        font-size: 14px;
        font-weight: bold;
        text-align: left;
        min-width: 180px;
    }
    .txnType{
        font-size: 16px;
        font-weight: bold;
        text-align: left;
        min-width: 180px;
    }
    .value {
        color: #31455E;
        font-weight: 500;
        font-size: 14px;
    }
    .fontSize{
        font-weight: 500;
        font-size: 14px;
    }
}

@media only screen and (min-width: 300px) and (max-width:350px)   {
    .portfolioDetailsResponsive{
        /* width: 300px; */
        /* margin-left: -20px; */
        margin: 10px;
    }
}
@media only screen and (min-width: 350px) and (max-width:400px)   {
    .portfolioDetailsResponsive{
        /* width: 350px; */
        /* margin-left: -25px; */
        margin: 10px;
    }
}
@media only screen and (min-width: 400px) and (max-width:600px)   {
    .portfolioDetailsResponsive{
        /* width: 400px; */
        /* margin-left: -25px; */
        margin: 10px;
    }
}