html,
body,
#root,
.App {
  height: 100% !important;
  overflow: hidden;
}

@font-face {
  font-family: "inter";
  src: local("inter"),
    url("./fonts/Inter-Medium.ttf") format("truetype");
  /* font-weight: bold; */
}

.sticky {
  position: fixed;
  top: 0;
  width: 100%;
}

/* 
@font-face {
  font-family: "inter";
  src: local("inter"), url("./fonts/inter.ttf") format("truetype");
} */


/* @-moz-document url-prefix() {
  html,body{ scrollbar-width: thin;
  }
} */
/* .MuiTableContainer-root{
  overflow-x: hidden !important;
} */
body {
  margin: 0;
  width: 100% !important;
  height: 100% !important;
  padding-right: 0px !important;
  font-family:'inter' !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: overlay;
  position: relative;
  /* overflow: overlay !important; */
  /* //overflow-x:unset !important; */
  scrollbar-width: thin;
  -moz-scrollbar-width: thin !important;
  /* -moz-scrollbar-width:1; */
}

.MuiTypography-root {
  font-family: "inter" !important;
}

.MuiTypography-button {
  font-family: "inter" !important;
}
h1,
h2,
h3,
h4,
h5,
h6,
p,
table,
tbody,
thead,
tr,
td,
div,
button,
span {
  font-family: "inter" !important;
}

.MuiDrawer-paper {
  background-color: #1359a0 !important;
  color: #fff !important;
}
code {
  font-family: "inter",
    monospace;
}

::placeholder {
  color: #e8e8e8 !important;
  font-weight: 400;
}

.divider {
  border: none;
  height: .05em;
  margin: 0;
  flex-shrink: 0;
  background-color: rgba(0, 0, 0, .12);
}

.MuiTabScrollButton-root {
  width: 14 !important;
}

.MuiGrid-root.MuiGrid-container.MuiGrid-spacing-xs-4>div:first-child {
  border-right: solid 1px #e1ecf6;
  border-radius: 0;
  border-radius: 12px 0px 0px 0px !important;
  /* padding: 0% 5%; */
  /* padding-right: 100px; */
}

.MuiGrid-root.MuiGrid-container.MuiGrid-spacing-xs-4>div:last-child {
  /* border-right: solid 1px #e1ecf6; */
  border-radius: 0;
  border-radius: 10px 10px 0px 0px !important;
}

.text-field-cursor {
  caret-color: rgb(175, 118, 46);
}
input {
  caret-color: rgb(175, 118, 46);

}

/* .css-2613qy-menu{
  margin-top: 0px !important;
  background-color: #1359a1 !important;

} */
.cardmenu1 .MuiGrid-root.MuiGrid-container.MuiGrid-spacing-xs-4>div:nth-child(2)>div {
  border-right: solid 1px #e1ecf6;
  padding-right: 4em;
}

/* 
.css-1hwfws3{
  padding: 2px 8px 2px 35px !important;
} */
.css-1wy0on6 {
  /* //  position: absolute; */
  padding-top: 6px;
  padding-left: 6px;
}

.MuiAvatar-square {
  margin-top: 7px !important;
}


.Portfolio-removeborder-110 {
  border-right: 0 !important;
}

.PrivateTabIndicator-colorSecondary-88 {
  background-color: rgb(175, 118, 46) !important;
}

/* .MuiTab-root:focus {
  color: rgb(175, 118, 46) !important;
} */
.MuiTab-root {
  max-width: max-content !important;
  min-width: max-content !important
}

.PrivateTabIndicator-root {
  width: max-content !important;
}

.MuiPaper-elevation4 {
  box-shadow: revert !important;
}

.MuiTabs-flexContainer {
  display: flex;
  border-bottom: solid 1px #e4e5e8;
}

.MuiTabs-root+div {
  margin-top: 1em !important;
}

.MuiTab-textColorInherit.Mui-selected {
  opacity: 1;
  color: #af762e !important;
  font-weight: 500 !important;
  /* padding: 2px !important; */
  padding: 6px 12px 6px 12px !important;
  font-size: 14px !important;
}

.MuiTab-textColorInherit {
  font-size: 14px !important;
}

.MuiTab-root {
  text-transform: capitalize !important;
  font-weight: normal !important;
}

/* commendedby yogitha on 28/05/2021 */
/* .MuiPaper-rounded {
  border-radius: 10px !important;   
} */

#profile-icon:hover {
  color: #faf7f2 !important;
}

.MuiTypography-displayBlock {
  display: block;
  /* // line-height: 0.5 !important; */
  font-size: 14px !important;
}

.buy-sell-background {
  background-color: rgb(175, 118, 46) !important;
}

/* .MuiListItem-gutters:hover {
  background-color: #faf7f2 !important;
  color: #af772d !important;
} */
#popup-tab span {
  font-size: 12px !important;
  padding-bottom: 0.7em;
}

/* #user-name span {
  font-size: 12px !important;
  font-weight: bold !important;
} */

#cardDisplay>.MuiPaper-elevation1 {
  box-shadow: revert !important;
}

.MuiPaper-elevation1 {
  box-shadow: none !important;
}

#card_shadow {
  box-shadow: 0 0 20px 0 rgb(19 89 161 / 10%);
}

.MuiChip-sizeSmall {
  height: 26px !important;
}

.MuiChip-labelSmall {
  padding-left: 13px !important;
  padding-right: 13px !important;
}

/* style added  yogitha on 24-06-2021 for  menu active class */
.menuclassActive:active {
  background-color: #ADD8E6;

}

/* .animation{
        width: 50%;
        height: 100%;
        z-index: 10;
        top: 0;
        right: 0;
        position: fixed;
        overflow: hidden;
        transform: translateY(-100%);
}
.animation.transition{
  transform: translateY(0%);
} */

/* .show{
  transition-property: visibility, opacity;
  transition-duration: 0s, 1s;
  transition-delay: 0s, 1s;
}
.hide{
  opacity: 0;
  visibility: hidden;
  transition-property: opacity, visibility;
  transition-duration: 1s, 0s;
  transition-delay: 0s, 1s;
} */

.hide {
  background: #1359a0;
  color: #FFFFFF;
  width: 100%;
  height: 0;
  overflow: hidden;
  transition: 1s height ease-in-out;
  text-align: left;
  padding-left: 14%;
  flex-basis: 100%;
  margin-left: 16%;
  line-height: 2.5;
  box-shadow: 0 1px 5px 0 #f5f5f5;
  position: absolute;
  /* margin-top: -4%; */
  top: 0px;
}

.show {
  background: #1359a0;
  color: #FFFFFF;
  width: 100%;
  overflow: hidden;
  transition: 1s height ease-in-out;
  height: 100vh;
  overflow: hidden;
  transition: 1s height ease-in-out;
  text-align: left;
  padding-left: 14%;
  flex-basis: 100%;
  margin-left: 16%;
  line-height: 2.5;
  box-shadow: 0 1px 5px 0 #f5f5f5;
  /* position: relative; */
  position: absolute;
  /* margin-top: -4%; */
  top: 0px;
}

/* tr td p { 
  font-size: 13px !important;
} */

/* @media (max-width:1024px) {
  tr td p {
    font-size: 13px !important;
  }

} */
.bold-table {
  font-weight: 500 !important;
}

.bold-value {
  font-weight: 500 !important;
  font-size: 14px !important;
}

/* Denis *

/* .MuiButtonBase-root>span{
    display: none !important;
}  */
.sc-iBPRYJ {
  display: none !important;
}

/* */
/* circle - carosuel */
/* .lgksPe:hover:enabled {
  color: #af772d !important;
  background-color: transparent !important;
}
.lgksPe:focus:enabled {
  color:white !important;
  background-color:  #af772d !important;
  box-shadow: white !important;
}
.lgksPe {
  color: #af772d !important;
  background-color: transparent !important;
  width: 50px !important;
  height: 40px !important;
  min-width: unset !important;
  font-size: 26px !important;
  line-height: 39px !important;
  box-shadow: 0 0 2px 0px #af772d !important; 
} */

/* .iLquPE {
  color: #af772d !important;
  background-color: transparent !important;
  box-shadow: 0 0 2px 0px #af772d !important; 
  width: 50px !important;
  height: 40px !important;
  min-width: auto !important;
  font-size: 26px !important;
  line-height: 39px !important;
  opacity: 100px;
}*/
.rec.rec-arrow:disabled {
  cursor: not-allowed;
  opacity: 0.3 !important;
  display: none;
}

.rec.rec-arrow:hover:enabled {
  color: white !important;
  background-color: #af772d !important;
  width: 47px !important;
  height: 40px !important;
  min-width: unset !important;
  font-size: 26px !important;
  line-height: 39px !important;
  box-shadow: 0 0 2px 0px #af772d !important;
}

.rec.rec-arrow:focus:enabled {
  color: #af772d !important;
  background-color: transparent !important;
  width: 47px !important;
  height: 40px !important;
  min-width: unset !important;
  font-size: 26px !important;
  line-height: 39px !important;
  box-shadow: 0 0 2px 0px #af772d !important;
}

.rec.rec-arrow {
  color: #af772d !important;
  background-color: transparent !important;
  width: 47px !important;
  height: 40px !important;
  min-width: unset !important;
  font-size: 26px !important;
  line-height: 39px !important;
  box-shadow: 0 0 2px 0px #af772d !important;
}

.MuiBadge-anchorOriginTopRightRectangle {
  top: auto !important;
  right: auto !important;
  left: 7px !important;
  background-color: transparent !important;
}

@media (min-width: 600px) {
  ::-webkit-scrollbar {
    -webkit-appearance: none;
  }

  ::-webkit-scrollbar:vertical {
    width: 6px;
  }

  ::-webkit-scrollbar:horizontal {
    height: 6px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #c7c7c7;
    border-radius: 10px;
    border: 2px solid #ffffff;
  }

  ::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #f1f1f1;
  }

  .MuiToolbar-gutters {
    padding-left: 18px !important;
    padding-right: 10px !important;
  }
}

.lable {
  color: #1359a1 !important;
  margin-left: 30px;
  font-weight: bold;
  cursor: pointer;
}

.MuiListItemText-root>span {
  padding-bottom: 15px !important;
}

/* Denis */
.showmore {
  border: solid 1px #31455e !important;
  background-color: #fff !important;
  width: auto !important;
  height: 35px !important;
  font-size: 14px !important;
  color: #31455e !important;
  border-radius: 20px !important;
  margin-top: 30px !important;
  margin-bottom: 30px !important;
  padding-left: 15px !important;
  padding-right: 15px !important;
  font-weight: 500 !important;
}

.showmore:hover {
  background: #31455e !important;
  color: #fff !important;
}

.positionChart {
  position: unset !important;
}

.boxdiv {
  height: 10px;
  width: 10px;
  border: double;
  border-color: aliceblue;
}

.grid-contain {
  max-height: 47px !important;
  border-bottom: 1px solid #dadae4 !important;
}

.mainDiv div ul :hover {
  color: rgba(0, 0, 0, 0.87) !important;
}

/* Chart -  denis */
.mainDiv {
  width: calc(100% + 18px) !important;
  margin: 35px !important;
  /* Modified by Celsia  */
}

.left {
  margin-left: -19px;
}

.right {
  margin-left: 130px;
}

/* .MuiBackdrop-root
{
    opacity:0.5 !important;
   
} */
.MuiPaper-elevation24 {
  box-shadow: none !important;
}

/* Scheme Table - denis */
.table th {
  font-size: 12px !important;
  text-transform: uppercase;
  /* text-align: right!important; */
  color: #8b939b !important;
  font-weight: 500;
}

.table tbody tr td {
  font-size: 14px !important;
}

.table tbody tr td:first-child {
  font-weight: bold !important;
  color: #333333 !important;
  padding-left: 24px !important;
  table-layout: fixed;
  white-space: nowrap;
}

.table thead tr th:first-child {
  padding-left: 24px !important;
}

.table tbody tr td:last-child {
  padding-right: 24px !important;
  color: #3b3b3b;
  font-weight: 500;
}

.MuiTableCell-stickyHeader {
  z-index: 1 !important;
}

.table thead tr th:last-child {
  padding-right: 24px !important;
}

/* .table tbody tr:nth-child(odd) {
  background-color: #fdfdfd !important;
} */
.table-big tbody tr:nth-child(odd) {
  background-color: #fdfdfd !important;
}

.table {
  margin-top: -10px !important;
}

.table tbody tr td:nth-child(3) {
  color: #909090 !important;
}

.iLquPE+.lgksPe {
  margin-right: 10px !important;
  margin-left: 10px !important;
}

/* Denis */
.card-main {
  border-radius: unset !important;
  border-bottom-left-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
}

.lable.active {
  color: rgb(175, 118, 46) !important;
}

/* Line Chart Denis */
/* Start */
g.recharts-layer.recharts-cartesian-axis.recharts-yAxis.yAxis line {
  display: none !important;
}
g.recharts-cartesian-grid-vertical line:first-child {
  display: none !important;
}
g.recharts-cartesian-grid-vertical line:last-child {
  display: none !important;
}

.barchart-rechart .recharts-rectangle.recharts-tooltip-cursor {
  fill: white !important;
}

/* End */

/* Denis */
/* .MuiChip-root:hover{
  color: #fff !important;
} */

/* Denis */
.btn-sidetab:hover {
  background: #1359a1 !important;
  color: #fff !important;
}

.list-Table tbody tr td>p {
  font-size: 14px !important;
}

@media only screen and (max-width: 1024px) {
  .list-Table tbody tr td>p {
    font-size: 12px !important;
  }

  .tblCellPTag1 {
    font-size: 16px !important;
    font-weight: 600 !important;
  }

  .tblCellPTag2 {
    font-size: 14px !important;
    font-weight: 600 !important;
  }

  .tblCellPTag {
    font-size: 13px !important;
  }

  .btn-sidetab {
    text-transform: none !important;
    width: 65% !important;
  }

  /* .tblHeaderRow {
     padding-left:10px !important;
   } */
}

/* .rechart-react-angle .rechart-tooltip-cursor{
   fill: white !important;
 } */
/* @media only screen and (max-width: 1440px) {
    .gridtwo{
      margin-left: 74px !important;
    }
    .grid-one{
      margin-left: -74px !important;
    }
 }


 .gridtwo{
  margin-left: 11% !important;
}
.grid-one{
  margin-left: -11% !important;
} */
/* .sideMenuLink {
 position: fixed !important;
} */
/* .sideMenuLink .popoverResponsive{
  display: block !important;
  position: fixed  !important;
} */

/* Denis - Yogitha */
@media only screen and (max-width: 1024px) {
  .loginFormsidewrap {
    color: rgba(0, 0, 0, 0.87);
    height: 100%;
    padding: 90px !important;
    border-radius: 0;
    background-color: "#fff";
  }

  .validationMessage {
    right: 23px !important;
  }

  /* commendedby yogitha for mobile responsive for 11/05/2021 */
  /* .loginEmail {
    right: 23px !important;
  }
  .loginPassword {
    right: 23px !important;
  } */
  .btnLoginSubmit {
    width: 233px !important;
    /* margin-right: 30px !important; */
  }

  .loginHeading {
    margin-left: 13px !important;
    max-width: 234px !important;
  }

  .passwordShowIcon span {
    margin-right: 10px !important;
  }

  .investmentToggleButton button {
    width: 93px !important;
  }

  .mainDiv {
    width: calc(100% + -28px) !important;
    margin: 36px !important;
  }

  .mainDiv div ul .right {
    margin-left: 67px !important;
  }

  .verticalLineHr {
    height: 214px !important;
  }

  .secoundrow-scheme {
    letter-spacing: none !important;
  }

  .scheme-data {
    margin-top: 18px !important;
    margin-left: -3em !important;
  }

  .data-img {
    padding-top: 35px;
    margin-left: 16px;
  }

  #tab {
    margin-left: 1.9em;
    margin-top: 1.9em;
  }

  .tab-left {
    margin-left: 4em !important;
    margin-top: 1em !important;
  }

  #menu1 {
    margin-left: -8px !important;
    margin-top: 17px !important;
  }

  .grid {
    margin-right: -39px !important;
  }

  .sub-details {
    margin-left: -27px !important;
    margin-top: 5px !important;
  }

  .sub-detailsRight {
    margin-top: 10px;
  }

  #secondrow1,
  #secondrow2,
  #secondrow3,
  #secondrow4,
  #secondrow5,
  #secondrow6,
  #titlesecondrowheading {
    font-size: 12px !important;
  }

  /* .Dropdown-control  {
    margin-left: 69px !important;
    width: 203px !important;
  }
   .Dropdown-root{
    width: 53% !important;
  } */
  /* .MuiButton-root {
    margin-right: 100px !important;
    margin-left: 69px !important;
     width: 65%!important;
  } */

  .MuiInput-underline:before {
    /* width: 218px !important; */
    /* commendedby yogitha textbox alignment changes on 11/05/2021 */
    /* margin-left: 40px !important; */
    right: 0;
    bottom: 0;
    content: "\00a0";
    position: absolute;
    transition: border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-bottom: 1px solid rgba(0, 0, 0, 0.42);
    pointer-events: none;
    /* width: 219px !important; */
  }

  .loginEmail .MuiInputLabel-formControl {
    top: 0;
    left: 0 !important;
  }

  .loginPassword .MuiInputLabel-formControl {
    top: 0;
    left: 0 !important;
  }

  .txtLineClass {
    margin-left: 5px !important;
    right: 0;
    bottom: 0;
    content: "\00a0";
    position: absolute;
    transition: border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-bottom: 1px solid rgba(0, 0, 0, 0.42);
    pointer-events: none;
    width: 297px !important;
  }

  .assetswitch {
    margin-right: 120px;
    margin-left: 21px;
  }

  /* commendedBy yogtiha on 31/05/2021 */
  /* .MuiPaper-root h3 {
    text-align: left !important;
    padding-left: 79px !important;
  } */
  .MuiTypography-paragraph {
    padding: 8px !important;
  }

  .MuiAvatar-square {
    margin-left: 7px !important;
  }

  .sideMenuLink :first-child {
    max-width: 80px !important;
  }

  .tablePortfolio {
    padding: 1px !important;
  }

  /* .cbSelect{
  max-width: 300px  !important ;
}   
.cbSelectOne{
  max-width: 300px  !important;
} */
  .css-1pcexqc-container {
    width: 12em !important;
    overflow-x: hidden !important;
  }

  .cbSelect .css-11unzgr {
    /* font-size: 10 !important; */
    overflow-x: hidden !important;
  }

  .cbSelectOne .css-11unzgr {
    /* font-size: 10 !important; */
    overflow-x: hidden !important;
  }

  .cbselectSpan {
    margin-right: -17px !important;
  }

  .cbselectSpanOne {
    margin-right: -19px !important;
  }
}

.scheme-data {
  margin-top: 10px;
  margin-left: -2.9em;
}

/* styles added by yogitha */
@media only screen and (max-width: 1440px) {
  .cbselectSpan {
    margin-right: -27px !important;
  }

  .cbselectSpanOne {
    margin-right: 0px !important;
  }

  .tblCellPTag {
    font-size: 13px !important;
    /* font-size: 10px !important; */
  }

  .cbSelect div .css-1r4vtzz {
    max-width: 115% !important;
  }

  .cbSelectOne .css-1pcexqc-container {
    background-color: white !important;
    margin-top: -40px !important;
    margin-left: -5px !important;
    overflow: hidden !important;
    position: absolute !important;
    /* inset: 0px  !important;     */
    /* z-index: 1  !important; */
  }
}

.MuiTypography-paragraph {
  /* // margin: 8px !important; */
}

.MuiAvatar-square {
  margin-top: 7px !important;
}

.carosule-table tbody tr :first-child {
  font-size: 18px !important;
  font-weight: 500 !important;
}

.tblHeader {
  padding-left: 4px !important;
}

.tblHeaderDividend {
  table-layout: fixed;
  width: 100px !important;
}

/* .cbSelect{
 width: 180px !important;
}
.cbSelectOne{
  width: 180px !important;
} */
#grid1Amount #outlined-adornment-amount:focus {
  border-color: rgb(175, 119, 45) !important;
}

/* 
@media only screen and (min-width: 2560) {
  #grid1Amount{
      margin-right: 15.3em !important;
  }
} */
/* @media screen and(max-width: 1024px){
  .investmentDetails{
      font-size: 23px !important;
  }
} */
.verticalLineHr {
  height: 288px !important;
}

/* .css-48ayfv ~ div{
  z-index: 1 !important;
 
 } */
/* .MuiPaper-elevation8{
   box-shadow: '0px 5px 5px -3px rgb(0 0 0 / 2%), 0px 8px 12px 1px rgb(0 0 0 / 6%), 0px 3px 10px 2px rgb(0 0 0 / 0%)';
 } */
@media only screen and (min-width: 1024px) and (max-width: 1339px) {

  /* the search label width increased */
  .searchLabel .MuiInputBase-input {
    width: 203px !important;
  }

  #card {
    margin-top: 18px;
  }

  .sub-details-left {
    margin-top: 2em !important;
  }

  .avatar {
    padding-top: 50px !important;
  }

  .clearIconHeader {
    margin-left: 22px !important;
    position: fixed !important;
  }

  /* .mulicheckboxes .css-1jllj6i-control .css-1hwfws3 div{
  margin-left: 20px !important;
  }
.css-1wy0on6 div svg{
  margin-left: -237px !important;
} */
  .cbAmcFilter .MuiSvgIcon-root {
    margin-left: -4px !important;
    margin-bottom: -4px !important;
  }
}

@media only screen and (min-width: 1440px) {

  /* the search label width increased */
  .searchLabel .MuiInputBase-input {
    width: 326px !important;
  }

  .clearIconHeader {
    margin-left: 120px !important;
    position: fixed !important;
  }

  /* .mulicheckboxes .css-1jllj6i-control .css-1hwfws3 div{
  margin-left: 20px !important;
  }
.css-1wy0on6 div svg{
  margin-left: -237px !important;
} */
  .cbAmcFilter .MuiSvgIcon-root {
    margin-left: -4px !important;
    margin-bottom: -4px !important;
  }

  #card {
    margin-top: -43px;
  }

  /* .filterRating{
    z-index: -1 !important;
  }
  .filterAvailableToInvestCross {
    z-index: -1 !important;
  } */
}

@media only screen and (min-width: 1880px) {
  .clearIconHeader {
    margin-left: 455px !important;
    position: fixed !important;
  }
}

/* the search label width increased */
/* .searchLabel .MuiInputBase-input {
  width: 326px !important;
}
.clearIconHeader{
margin-left: 120px !important;
position:fixed !important;
} */

.secoundrow-scheme {
  margin-top: 13px;
  margin-left: -25px;
}

.sub-details {
  float: left;
  margin-left: -22px;
}

.sub-detailsRight {
  margin-right: 5em;
}

@media only screen and (max-width: 1024px) {
  /* commendedBy yogtiha on 31/05/2021 */
  /* .MuiPaper-root h3 {
    text-align: left !important;
    padding-left: 50px !important;
  } */
}

.payment-details {
  padding: 12px;
  max-height: 100%;
  width: 100%;
  background-color: rgb(250, 247, 242);
  /* height: 17vh; */
  border-radius: 10px;
}

@media only screen and (min-width: 1440px) {
  .loginFormControl {
    overflow: hidden;
  }

  .payment-details {
    min-height: 100% !important;
  }
}

@media only screen and (min-width: 1024px) {
  .payment-details {
    min-height: 23vh !important;
  }

  .loginFormControl {
    overflow: hidden;
  }
}

@media only screen and (min-width: 1250px) and (max-width: 1300px) {
  .payment-details {
    min-height: 100% !important;
  }

  .loginFormControl {
    overflow: hidden;
  }
}

@media only screen and (min-width: 601px) {
  .cbSelect {
    width: 100% !important;
  }

  .cbSelectOne {
    width: 100% !important;
  }
}

@media only screen and (max-width: 600px) {
  .cbSelect {
    width: 168px !important;
  }

  .cbSelectOne {
    width: 192px !important;
  }
}

/* .cbSelect {
  width: 265px;
}
.cbSelectOne {
  width: 265px;
} */

/* Max-width modified by Celsia on 13/05/2021 */

@media only screen and (min-width: 320px) and (max-width: 425px) {

  /* styles added by yogitha 13-05-2021 */
  .list-Table {
    width: 100%;
  }


  ::-webkit-scrollbar {
    -webkit-appearance: none;
  }

  ::-webkit-scrollbar:vertical {
    width: 6px;
  }

  ::-webkit-scrollbar:horizontal {
    height: 6px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #c7c7c7;
    border-radius: 10px;
    border: 2px solid #ffffff;
  }

  ::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #f1f1f1;
  }

  .MuiInput-underline:before {
    /* width: 218px !important;
    margin-left: 40px !important; */
    right: 0;
    bottom: 0;
    content: "\00a0";
    position: absolute;
    transition: border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-bottom: 1px solid rgba(0, 0, 0, 0.42);
    pointer-events: none;
    /* width: 219px !important; */
  }

  /* Commented by yogitha textbox aiignment changes on 11/05/2021  */

  .loginEmail .MuiInputLabel-formControl {
    top: 0;
    left: 0 !important;
  }

  .loginPassword .MuiInputLabel-formControl {
    top: 0;
    left: 0 !important;
  }


  /* .tablePortfolio{
    display: none ;
  } */
  /* .portfolioSizeChange{
     margin-top: 35% !important;
  } */
  /* .PortfolioTableHead{
    display: none !important;
  } */
  /* .InvestedValue{
  flex: 0 0 50% !important;
}
.MargetValue{
  flex: 0 0 50% !important;
} */

  .MuiInput-underline:after {
    /* left: 7% !important; */
    right: 0;
    bottom: 0;
    content: "";
    /* width: 10pX !important; */
    position: absolute;
    transform: translateX(9%);
    /* transition: transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms; */
    border-bottom: 2px solid #3f51b5;
    pointer-events: none;
  }

  .MuiInput-underline:before {
    left: 0;
    right: 0;
    bottom: 0;
    /* width: 83% !important; */
    content: "\00a0";
    position: absolute;
    transition: border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-bottom: 1px solid rgba(0, 0, 0, 0.42);
    pointer-events: none;
  }

  .portfoliobutton {
    display: none !important;

  }

  /* commended by yogitha for mobile responsive aso show the sideMenu on 09 june 2021 */
  /* .sideMenuLink{
  display: none !important;
} */
  /* .menuButtonLines{
  display: block !important;
} */


  #card_id .MuiGrid-root.MuiGrid-container.MuiGrid-spacing-xs-4>div:first-child {
    box-shadow: none;
    flex: 0 0 100% !important;
    text-align: center !important;
    height: 20% !important;
    border-right: solid 0px #e1ecf6 !important;
    padding: revert;
  }

  #card_id .MuiGrid-root.MuiGrid-container.MuiGrid-spacing-xs-4>div:nth-child(2) {
    box-shadow: none;
    flex: 0 0 50% !important;
    text-align: center !important;
    height: 20% !important;
  }

  #card_id .MuiGrid-root.MuiGrid-container.MuiGrid-spacing-xs-4>div:nth-child(3) {
    box-shadow: none;
    flex: 0 0 50% !important;
    text-align: center !important;
    height: 20% !important;
  }

  #card_id .MuiGrid-root.MuiGrid-container.MuiGrid-spacing-xs-4>div:nth-child(4) {
    box-shadow: none;
    flex: 0 0 50% !important;
    text-align: center !important;
    height: 70px !important;
  }

  #card_id .MuiGrid-root.MuiGrid-container.MuiGrid-spacing-xs-4>div:nth-child(5) {
    box-shadow: none;
    flex: 0 0 50% !important;
    text-align: center !important;
    height: 75px !important;
    /* Modified by Celsia */
    /* height: 20% !important; */
  }

  #card_id .MuiGrid-root.MuiGrid-container.MuiGrid-spacing-xs-4>div:last-child {
    height: 80px;
  }

  .portfolioChartContainer {
    margin-top: '55%  !important';
  }

  .MuiSvgIcon-root.menuIconPortfolio :first-child {
    color: #fff !important;
  }




  /* .tablePortfolio{
  overflow-x: 'auto  !important';
  width: '100% !important';
} */
  .portfolioChartSheets {
    flex: 0 0 100%;
    flex-direction: row;
    display: flex;
    justify-content: center;

  }
}

/* style added by yogitha side menu popup stricky desktop and mobile responsive on 19/06/2021  */
/* .popoverResponsive{
   position:fixed !important; 
   display: block !important;
 } */

@media only screen and (min-width: 425px) and (max-width: 601px) {
  .list-Table {
    width: 100%;
  }

  .list-Table tbody tr td>p {
    font-size: 12px !important;
    font-weight: "bold";
  }

  ::-webkit-scrollbar {
    -webkit-appearance: none;
  }

  ::-webkit-scrollbar:vertical {
    width: 6px;
  }

  ::-webkit-scrollbar:horizontal {
    height: 6px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #c7c7c7;
    border-radius: 10px;
    border: 2px solid #ffffff;
  }

  ::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #f1f1f1;
  }

  .MuiInput-underline:before {
    /* width: 218px !important;
      margin-left: 40px !important; */
    right: 0;
    bottom: 0;
    content: "\00a0";
    position: absolute;
    transition: border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-bottom: 1px solid rgba(0, 0, 0, 0.42);
    pointer-events: none;
    /* width: 219px !important; */
  }

  /* Commented by yogitha textbox aiignment changes on 11/05/2021  */

  .loginEmail .MuiInputLabel-formControl {
    top: 0;
    left: 0 !important;
  }

  .loginPassword .MuiInputLabel-formControl {
    top: 0;
    left: 0 !important;
  }


  /* .tablePortfolio{
      display: none ;
    } */
  /* .portfolioSizeChange{
       margin-top: 35% !important;
    } */
  /* .PortfolioTableHead{
      display: none !important;
    } */
  /* .InvestedValue{
    flex: 0 0 50% !important;
  }
  .MargetValue{
    flex: 0 0 50% !important;
  } */

  .MuiInput-underline:after {
    /* left: 7% !important; */
    right: 0;
    bottom: 0;
    content: "";
    /* width: 10pX !important; */
    position: absolute;
    transform: translateX(9%);
    /* transition: transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms; */
    border-bottom: 2px solid #3f51b5;
    pointer-events: none;
  }

  .MuiInput-underline:before {
    left: 0;
    right: 0;
    bottom: 0;
    /* width: 83% !important; */
    content: "\00a0";
    position: absolute;
    transition: border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-bottom: 1px solid rgba(0, 0, 0, 0.42);
    pointer-events: none;
  }

  .portfoliobutton {
    display: none !important;

  }

  /* commended by yogitha for mobile responsive aso show the sideMenu on 09 june 2021 */
  /* .sideMenuLink{
    display: none !important;
  } */
  /* .menuButtonLines{
    display: block !important;
  } */


  #card_id .MuiGrid-root.MuiGrid-container.MuiGrid-spacing-xs-4>div:first-child {
    box-shadow: none;
    flex: 0 0 100% !important;
    text-align: center !important;
    height: 20% !important;
    border-right: solid 0px #e1ecf6 !important;
    padding: revert;
  }

  #card_id .MuiGrid-root.MuiGrid-container.MuiGrid-spacing-xs-4>div:nth-child(2) {
    box-shadow: none;
    flex: 0 0 50% !important;
    text-align: center !important;
    height: 20% !important;
  }

  #card_id .MuiGrid-root.MuiGrid-container.MuiGrid-spacing-xs-4>div:nth-child(3) {
    box-shadow: none;
    flex: 0 0 50% !important;
    text-align: center !important;
    height: 20% !important;
  }

  #card_id .MuiGrid-root.MuiGrid-container.MuiGrid-spacing-xs-4>div:nth-child(4) {
    box-shadow: none;
    flex: 0 0 50% !important;
    text-align: center !important;
    height: 70px !important;
  }

  #card_id .MuiGrid-root.MuiGrid-container.MuiGrid-spacing-xs-4>div:nth-child(5) {
    box-shadow: none;
    flex: 0 0 50% !important;
    text-align: center !important;
    height: 75px !important;
    /* Modified by Celsia */
    /* height: 20% !important; */
  }

  #card_id .MuiGrid-root.MuiGrid-container.MuiGrid-spacing-xs-4>div:last-child {
    height: 80px;
  }

  .portfolioChartContainer {
    margin-top: '55%  !important';
  }

  .MuiSvgIcon-root.menuIconPortfolio :first-child {
    color: #fff !important;
  }




  /* .tablePortfolio{
    overflow-x: 'auto  !important';
    width: '100% !important';
  } */
  .portfolioChartSheets {
    flex: 0 0 100%;
    flex-direction: row;
    display: flex;
    justify-content: center;

  }
}

/* .empty-div{
    display: none !important;
  } */
/* .div-footer{
    width: 100%;
    position: absolute;
    bottom: 0;
    margin-top: auto;
  } */
@media screen and (max-width:600px) {
  /* Modified by Celsia on 13/03/2021 */

  /* Modified by Celsia */
  /* Start */
  #card_id .MuiGrid-root.MuiGrid-container.MuiGrid-spacing-xs-4>div:first-child {
    border-bottom: solid 1px #e1ecf6 !important;
    /* padding: 10px !important; */
    padding-bottom: 10px !important;
    padding-top: 10px !important;
    /* end */
  }
}

/* commended by  yogitha on 17/05/2021 */

/* @media only screen and (width: 375px) {

  .portfolioSizeChange{
    margin-top: 35% !important;
    margin-left: -14px !important;
 }

 } */
/* commended by  yogitha on 17/05/2021 */
/* @media only screen and (width: 425px) {
     .portfolioSizeChange{
     margin-top: 35% !important;
  }
 } */

/* Added by Celsia on 11/05/2021 Start */
/* @media only screen and (max-width: 600px) {
  .MuiGrid-spacing-xs-4{
    width: calc(100% + 41px) !important;
    margin: -15px !important;
}
} */
/* end */

@media only screen and (width: 320px) {
  /* commended by  yogitha on 17/05/2021 */
  /* .portfolioSizeChange{
    margin-top: 68% !important;
    margin-left: -50px !important;
  } */

  /* commended by yogitha on 21-05-2021 */
  .FamilyDateChoosing {
    margin-top: '4% !important';
    margin-left: '-16% !important';
  }

  /* .tablePortfolio {
    width:'100% !important';
    overflow-x:'auto !important';
  } */
  /* commented by yogitha for mobile responsive on11/05/2021 */
  /* .loginFormControl{
  margin-top: -110%  !important;
 }
 #card_id .MuiGrid-root.MuiGrid-container.MuiGrid-spacing-xs-4 > div:first-child {
 } */
  #card_id .MuiGrid-root.MuiGrid-container.MuiGrid-spacing-xs-4>div:first-child {
    box-shadow: none;
    flex: 0 1 100% !important;
    text-align: center !important;
    height: 20% !important;
    border-right: solid 0px #e1ecf6;
    padding-right: 10px;
    /* Modified by celsia  on 12/03/2021*/
    /* margin-left: 7px; Modified by celsia  on 12/03/2021*/
  }

  #card_id .MuiGrid-root.MuiGrid-container.MuiGrid-spacing-xs-4>div:nth-child(2) {
    box-shadow: none;
    flex: 0 0 100% !important;
    text-align: center !important;
    height: 20% !important;
    /* margin-left: 7px;  Modified by celsia on 12/03/2021 */

  }

  #card_id .MuiGrid-root.MuiGrid-container.MuiGrid-spacing-xs-4>div:nth-child(3) {
    box-shadow: none;
    flex: 0 0 100% !important;
    text-align: center !important;
    height: 20% !important;
    /* margin-left: 7px; Modified by celsia */
  }

  #card_id .MuiGrid-root.MuiGrid-container.MuiGrid-spacing-xs-4>div:nth-child(4) {
    box-shadow: none;
    flex: 0 0 100% !important;
    text-align: center !important;
    height: 20% !important;
    /* margin-left: 7px; Modified by celsia on 12/03/2021 */
  }

  #card_id .MuiGrid-root.MuiGrid-container.MuiGrid-spacing-xs-4>div:nth-child(5) {
    box-shadow: none;
    flex: 0 0 100% !important;
    text-align: center !important;
    height: 75px !important;
    /* margin-left: 7px; Modified by celsia on 12/03/2021*/
  }

  #card_id .MuiGrid-root.MuiGrid-container.MuiGrid-spacing-xs-4>div:last-child {
    height: 80px;
  }

  .portfolioChartSheets {
    flex: 0 0 100%;
    flex-direction: row;
    display: flex;
    justify-content: center;
  }

  /* .tablePortfolio{
  overflow-x: 'auto  !important';
  width: '100% !important';
} */
}

/* @media only screen and (width: 768px) {
    .portfolioChartSheets{
      flex: 0 0 100%;
      flex-direction: row;
      display: flex;
      justify-content: center;
        
    
}
 
 }   */
/* .menuButtonLines{
  display: none !important;
} */

/* .multipleSchemeTabs .iLquPE {
  width:0px !important;
  margin-left: 2% !important;
} 

.multipleSchemeTabs .lgksPe {
  width:0px !important;
  margin-left: 1.5%!important;
} 
.multipleSchemeTabs .lgksPe:disabled{
  width:0px !important;
  margin-left: 1.5%!important;
}

.multipleSchemeTabs .cukhLH{
  margin-left: 2% !important;
}

.multipleSchemeTabs .iLquPE:disabled{
  margin-left: 2% !important;
}
.multipleSchemeTabs .cukhLH {
 width: 87% !important;
}

.multipleSchemeTabs  .ewJlEB  {
  padding-bottom : 2px !important;
} */

.expBox1 {
  background: #ae275f;
  width: 50px;
  height: 50px;
  line-height: 50px;
  color: #fff;
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  border-radius: 7px;
}

/* #openingHead{
    margin-bottom: "3.5%" !important;
    padding-left:'4.5%' !important;
  } */
@media only screen and (min-width: 320px) and (max-width:400) {
  .MuiGrid-root.MuiGrid-container.MuiGrid-spacing-xs-4>div:nth-child(5) {
    height: 60px;
  }
}

.css-1jllj6i-control {
  border: none !important;
}

.empty-div {
  display: none;
}

@media only screen and (min-width: 320px) and (max-width: 600px) {
  .MuiPaper-rounded {
    border-radius: 10px 10px 10px 10px !important;
    /* Modified by Celsia on 13/05/2021 */
  }

  .cbSelect div .css-1r4vtzz {
    min-width: 161% !important;
  }

  .cbSelectOne div .css-1r4vtzz {
    min-width: 142% !important;
  }

  .showmoreCard .sc-gsTCUz .tXlBS {
    width: 'unset';
  }

  .empty-div {
    display: block !important;

  }

}

@media only screen and (min-width: 300px) and (max-width: 350px) {
  .MuiButton-containedSizeLarge {
    width: 80% !important;
    margin-left: 25px !important;
    ;
  }
}

@media only screen and (min-width: 350px) and (max-width: 600px) {
  .MuiButton-containedSizeLarge {
    width: 85% !important;
    margin-left: 25px !important;
    ;
  }
}


@media only screen and (min-width: 375px) and (max-width: 424px) {
  .cbSelect div .css-1r4vtzz {
    min-width: 195% !important;
  }

  .cbSelectOne div .css-1r4vtzz {
    min-width: 171% !important;
  }

}

@media only screen and (min-width: 425px) and (max-width: 600px) {
  .cbSelect div .css-1r4vtzz {
    min-width: 225% !important;
  }

  .cbSelectOne div .css-1r4vtzz {
    min-width: 198% !important;
  }
}

.desktopResponsive {
  display: block;
}

.mobileResponsives {
  display: none;
}

@media only screen and (min-width: 320px) and (max-width: 800px) {
  .sideMenuLink :first-child {
    max-width: unset !important;
    display: inline-flex;
    /* max-width: 80px !important; */
  }
}

@media only screen and (min-width: 320px) and (max-width: 600px) {
  .desktopResponsive {
    display: none;
  }

  .mobileResponsives {
    display: block;
  }

  .MuiGrid-root.MuiGrid-container.MuiGrid-spacing-xs-4>div:first-child {
    border-right: solid 1px #e1ecf6;
    border-radius: 0;
    border-radius: 10px 10px 0px 0px !important;
  }

  .MuiGrid-root.MuiGrid-container.MuiGrid-spacing-xs-4>div:last-child {
    /* border-right: solid 1px #e1ecf6; */
    border-radius: 0;
    border-radius: 10px 10px 10px 10px !important;
  }

  /* styles added by yogitha for mobile responsive in carosule on 02/06/2021 */
  .cukhLH {
    overflow: hidden;
    position: relative;
    width: 100%;
    margin: 0px 0px;
  }

  .assetswitch {
    margin-right: 0;
    margin-left: 0;
  }

  .rc-menu__submenu>.rc-menu__item::after {
    content: "❯";
    position: absolute;
    right: -1rem !important;
  }

  .subMenuResponsive {
    top: -380px !important;
    left: -110px;
  }

  /* .div-footer{
  width: 100%;
  position: absolute;
  bottom: 0;
  margin-top: auto;
} */
  /* .empty-div{
  display: block !important;

} */
}

/* menu Icon changes by yogitha for tablet screen on 11 june 2021 */
@media only screen and (min-width: 600px) and (max-width: 1000px) {
  .MuiSvgIcon-root.menuIconPortfolio :first-child {
    color: #fff !important;
  }

}

.tableRadius>.MuiPaper-rounded {
  border-radius: 15 !important;
}

.Loader>.MuiCircularProgress-root {
  color: #af772d !important;
}

/* End */
/* added by dulcy */
.rec .rec-dot {
  display: none;
}
div:focus {
  outline: none;
}

@-moz-document url-prefix() {

  #scrollApp {
    scrollbar-width: thin !important;
  }
}