body {
    background: #f1f1f1;
}

.MuiPaper-elevation3 {
    box-shadow: 0 3px 20px 0 rgba(19, 89, 161, 0.1) !important;
    background-color: #ffffff !important;
    ;
    border-radius: 10px;
    padding: 25px 30px;
    min-height: 397px;
    height: auto;
    margin-bottom: 20px;
}


.header {
    width: 100%;
    padding-top: 200px;
    padding-bottom: 200px;

    -webkit-animation-name: headeranimation;
    -moz-animation-name: headeranimation;
    animation-name: headeranimation;
    -webkit-animation-duration: 1s;
    -moz-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-play-state: paused;
    -moz-animation-play-state: paused;
    animation-play-state: paused;
    -webkit-animation-timing-function: ease;
    -moz-animation-timing-function: ease;
    animation-timing-function: ease;

    background-position: center center;
    background-size: cover;

    animation-fill-mode: forwards;
    -webkit-animation-fill-mode: forwards;
    -moz-animation-fill-mode: forwards;
}

@keyframes headeranimation {
    to {
        padding-top: 20px;
        padding-bottom: 20px;
    }
}

@-webkit-keyframes headeranimation {
    to {
        padding-top: 20px;
        padding-bottom: 20px;
    }
}

@-moz-keyframes headeranimation {
    to {
        padding-top: 20px;
        padding-bottom: 20px;
    }
}
h1 {
    margin: 0 0 20px 0;

    color: rgba(10, 10, 10, .6);
}
div.form-group {
    margin-bottom: 0;
}
div.form-group>input.form-control.input-lg {
    border: 1px solid #fff;
    box-shadow: none;
}
div.form-group>input.form-control.input-lg:focus {
    border: 1px solid #666;
}

.search-bar-content {
    text-align: center;
}

.image-item {
    margin-top: 10px;
    margin-bottom: 10px;
}

.image-item img {
    width: 100%;
}

.loadingWrapper {
    background: rgba(255, 255, 255, 0.5);
    position: fixed;
    bottom: 16px;
    right: 16px;
    z-index: 999;
    padding: 16px;
}

.loading {
    box-sizing: border-box;
    width: 48px;
    height: 48px;
    margin: 0 auto 0 auto;

    -webkit-animation: spinner 1s ease infinite;
    -moz-animation: spinner 1s ease infinite;
    animation: spinner 1s ease infinite;

    border: 8px solid rgba(0, 0, 255, 0.5);
    border-right-color: transparent;
    border-radius: 50%;
}

@keyframes spinner {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

@-webkit-keyframes spinner {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

@-moz-keyframes spinner {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}


/* Amar Rama moorthy */

.MuiAutocomplete-endAdornment {
    /* top: calc(50% - 14px); */
    right: 0;
    position: absolute;
}

.MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-endAdornment {
    right: 0px;
}

@-moz-document url-prefix() {

    :root {
        /* scrollbar-color: rgb(46,54,69) !important; */
        background-color: #c7c7c7;
        border-radius: 10px;
        /* border: 2px solid #ffffff; */
        scrollbar-width: thin !important;
    }
}

.spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    animation-name: spin;
    animation-duration: 500ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}