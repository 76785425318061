@media screen and(min-width: 600px){
.gridthree {
    flex-grow: 0;
    max-width: 55% !important;
    flex-basis: 50%;
}
}
.gridthree {
    flex-grow: 0;
    max-width: 55% !important;
    flex-basis: 50%;
}

.gridtwo{
    flex-grow: 1;
    max-width: 55% !important;
    flex-basis: 50%;
    /* margin-left: none; */
}
.grid-one{
    flex-grow: 1;
    /* margin-left: -40px; */
    /* max-width: 55% !important; */
    flex-basis: 50%;
}

@media screen and(max-width: 1024px){
    .grid-one {
        /* flex-grow: 0;
        max-width: 55% !important; */
        padding-left: 80px !important;
    }
    .investmentDetails{
        font-size: 23px !important;
    }
}

@media only screen and (min-width: 2560px){
   #checkbox{
        margin-left: -22em;
        margin-top: 10%;
        /* overflow: hidden !important;
        white-space: nowrap !important; */
   }
}

@media only screen and (max-width: 1024px){
    .investmentDetails{
        font-size: 20px !important;
    }
    #sideMainDiv{
        /* margin-left: 4em; */
    }
    #checkbox{
        margin-left: -1em;
        margin-top: 7px !important;
        overflow: hidden !important;
        white-space: nowrap !important;
        margin-top: 10%;
    }
}

@media only screen and (min-width: 1440px){
#grid1Amount{
    margin-right: 3.5em;
}
#checkbox{
    margin-left: -0.8em;
    margin-top: 10%;
    /* overflow: hidden !important;
    white-space: nowrap !important; */
}
}
/* Mobile Responsive Created By Muthu Lakshmi K */
@media only screen and (min-width: 320px) and (max-width:350px){
   
    #checkboxlabel{
        /* margin-left: -30px;        */
        margin-left:0;
    }
    #checkboxContent{
        font-size: 14px;
        /* margin-left: 20px; */
    }
    #checkbox{
        margin-left: 0;
        /* margin-left: -2em !important; */
        
    }
    
}
@media only screen and (min-width: 350px) and (max-width:400px){
   
    #checkboxlabel{
        margin-left:0;
        /* margin-left: -60px;        */
    }
    #checkboxContent{
        font-size: 14px;
        /* margin-left: 5px; */
    }
    #checkbox{
        margin-left: 0;
        /* margin-left: -2em !important; */
        
    }
}
@media only screen and (min-width: 400px) and (max-width:600px){
    #checkboxlabel{
        margin-left:0;
        /* margin-left: -80px;        */
    }
    #checkbox{
        margin-left: 0;
        /* margin-left: -2em !important; */
        
    }
}
/* .MuiSvgIcon-root{
    width: 20px!important; commented by dulcy; it affect all icon style
} */

