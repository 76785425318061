.expandtableContainerScroll {
  /* position: relative;
  margin: auto;
  overflow: auto;
  height: auto;
  cursor: context-menu;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.06);
  border-radius: 0px 0px 6px 6px;
  background-color: #ffffff;
  border-radius: 6px; */
  height: 350px;
    overflow-y: auto;

}


.expandtableContainerScroll table {
  width: 100%;
  /* min-width: 450px; */
  margin: auto; 
  border: solid 1px #ecf3fc;
}

.expandtablecontainer {
  border: none;
  width: 300%;
  border-collapse: collapse;
  /* margin-left: -30px; */
  /* border-top: solid 1px #eeeeee; */
}


::-webkit-scrollbar {
  width: 7px;
  height: 7px;
  cursor: pointer;
}



/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #c7c7c7;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #777;
}

.expandtableContainerScroll thead th {
  background: #ffffff;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  font-weight: 500;
}

/* safari and ios need the tfoot itself to be position:sticky also */
.expandtableContainerScroll tfoot,
.expandtableContainerScroll tfoot th,
.expandtableContainerScroll tfoot td {
  position: -webkit-sticky;
  position: sticky;
  bottom: 0;
  background: #3D4348;
  color: #fff;
  z-index: 0;
  font-weight: 500;
  font-size: 18px !important
}


.expandtablecontainer thead th {
  font-size: 12px !important;
  font-weight: 500 !important;
  color: #8b939b !important;
  text-transform: uppercase;
  text-align: right !important;
  min-width: 100px;
}

.expandtablecontainer th:nth-child(1) {
  text-align: left !important;
}

.expandtablecontainer .innerexpandable {
  border-bottom: 1px solid #e7e6e6!important;
  color: rgb(111, 111, 111)
}

.tableheadrow {
  border-bottom: 1px solid #e7e6e6 !important;
  cursor: auto;
}

.expandtablecontainer .innerexpandable {
  text-align: left
}

.expandtablecontainer td,
.expandtablecontainer th {
  padding: 15px 5px 15px 5px;
  text-align: right;
  /* padding: 15px 0px 15px 0px; */
}


/* .expandtablecontainer thead th{
    font-size: 12px!important;
    text-transform: uppercase !important;
    text-align: right!important;
  }  */
/* .expandtablecontainer th {
    font-size: 12px!important;
    text-transform: uppercase !important;
    text-align: right!important;
    color: #a7a7a7 !important
} */
.expandtablecontainer td .checkboxfillcolor {
  width: 15px;
  height: 15px;
  border: 1px solid black;
  /* margin-top: 5px; */
  cursor: pointer;
  background-color: rgb(255, 255, 255);
  -webkit-box-sizing: border-box;
}

.expandtablecontainer td .checkboxfillcolor div {
  width: 9px;
  height: 9px;
  text-align: center;
  background-color: rgb(255, 255, 255);
  /* position: absolute; */
  margin: 2px
}

.checkboxcontainer{
  width: 11px;
  height: 11px;
  border: double;
  -webkit-box-sizing: border-box;

}
.checkboxfilldiv{
  width: 100%;
  height: 100%;
  align-items: center;
}

.expandtablecontainer tfoot tr td:nth-child(1) {
  text-align: left;
}

.expandabletd {
  font-size: 18px !important;
  font-weight: 500 !important;
}
.expandtableContainerScroll{
  display: block;
  /* border: solid 1px #ecf3fc; */
}
.expandtableContainerBlock{
  display: none;
}

@media only screen and (max-width: 350px)   {
  .expandabletd {
    width:100px;
    font-size: 14px !important;
  }
  .checkboxcontainer{
    width: 8px;
    height: 8px;
    border: double;
    -webkit-box-sizing: border-box;
  }
  .bulletPointContainer{
    width: 20px !important;

  }
  .expandTotal{
    text-align: left;
    padding-left: 1px;
  }
  .expandtableContainerBlock{
    display: block;
    margin: 0px 0px;
    padding: 5px;
    border-radius: 10px;
  }
  .expandtableContainerScroll{
    display: none;
  }

  .expandtablecontainer thead th {
    min-width: 120px;
  }
  .expandtablecontainer{
    /* box-shadow: 0 0 5px 0 rgb(19 89 161 / 6%);
    border: solid 1px #ecf3fc; */
    background-color: #ffffff;
    padding: 0;
    height: 100px;
    margin-left: 0px;
    width: 100%;
    border: solid 1px #ecf3fc;
    /* border-radius: 8px;
    margin-bottom: 2px; */
  }
}
/* @media only screen and (min-width: 500px)   {
  .expandabletd{
    width:135px;
    font-size: 16px !important;
  }
  .expandtableContainerScroll{
    display: none;
  }
  .expandtableContainerBlock{
    display: block;
  }
  .expandtablecontainer{
    box-shadow: 0 0 5px 0 rgb(19 89 161 / 6%);
    border: solid 1px #ecf3fc;
    background-color: #ffffff;
    padding: 0;
    border-radius: 8px;
    margin-bottom: 2px;
    margin-left: -32px;
  }
}  */
@media only screen and (min-width: 350px) and (max-width:400px)  {
  .expandabletd{
    width:140px;
    font-size: 16px !important;
  }
  .expandTotal{
    text-align: left;
    padding-left: 1px;
  }
  .expandtableContainerScroll{
    display: none;
  }
  .expandtableContainerBlock{
    display: block;
    margin: 0px 0px;
   
    /* padding: 0px 10px 0px 5px; */
  }
  .expandtablecontainer{
    box-shadow: 0 0 5px 0 rgb(19 89 161 / 6%);
    border: solid 1px #ecf3fc;
    background-color: #ffffff;
    padding: 0;
    border-radius: 8px;
    margin-bottom: 2px;
    margin-left: 0px;
    width: 100%;
    /* margin-left: -35px; */
  }
}
@media only screen and (min-width: 350px) and (max-width:400px)  {
  .expandtablecontainer{
    /* width:100% !important; */
  }
}
@media only screen and (min-width: 400px) and (max-width:500px)  {
  .expandabletd{
    width:160px;
    font-size: 16px !important;
  }
  .expandTotal{
    text-align: left;
    padding-left: 1px;
  }
  .expandtableContainerScroll{
    display: none;
  }
  .expandtableContainerBlock{
    display: block;
    margin: 0px 10px;
  }
  .expandtablecontainer{
    box-shadow: 0 0 5px 0 rgb(19 89 161 / 6%);
    border: solid 1px #ecf3fc;
    background-color: #ffffff;
    padding: 0;
    border-radius: 8px;
    margin-bottom: 2px;
    margin-left: 0px;
    width: 100%;

    /* margin-left: -37px; */
  }
}
.expandabletableintd {
  font-size: 14px !important;
  font-weight: normal !important;
  color: #656565 !important;
  /* padding-top: 10px !important;
  padding-bottom: 10px !important; */
}

.expandabletablerupee {
  color: #909090 !important;
  font-weight: 500;
  display: inline-block;
  margin-right: 2px;
}
/* styles added by yogitha on 03/06/2021  for mobile responsive */
.desktop-responsive {
  display: block
}
.mobile-responsive {
  display: none;
}
/* styles added by yogitha on 03/06/2021 for mobile responsive */
@media only screen and  (min-device-width: 320px) and (max-device-width: 600px) {
  .desktop-responsive {
          display: none
          /* visibility:hidden */
  }
  .mobile-responsive {
          display: block;
          margin-left: 10px;
          /* visibility:visible */
  }
}