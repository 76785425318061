.exBox {
  box-shadow: 0 0 10px 0 rgba(19, 89, 161, 0.06);
  background-color: #ffffff;
  border-radius: 5px;
  text-align: center;
  padding: 20px;
}

.dx-c-bs4-container {
  display: flex;
  flex-direction: column;
  padding: 10px;
  height: 29em !important;
}
.recharts-legend-wrapper{
  width: 390px;
    height: auto;
    right: 5px;
    bottom: 5px;
    margin-top: -6em !important;
    position: relative !important;
}
/* .MuiInputBase-input{
  text-align: center !important;
  font-size: 25px !important;
} */

#standard-full-width{
  text-align: center !important;
  font-size: 25px !important;
}
#standard-width{
  /* modified on 12-05-2021 by yogitha */
  /* margin-left: -3% !important; */
  font-size: 15px !important;
}

#standard-basic{
  margin-left: -2% !important;
  font-size: 15px !important;
}

#standard-basic-width{
  margin-left: -2% !important;
  font-size: 15px !important;
}

.textbox-popup .MuiInput-underline:after{
  border-bottom: 2px solid #af762e !important;
}

.textbox-popup .MuiInput-underline:hover:not(.Mui-disabled):before {
  border-bottom: 1px solid #af762e !important;
}

 .MuiTab-textColorInherit.Mui-selected {
  opacity: 1;
  color: #af762e !important;
  font-weight: 500 !important;
}

.MuiButtonBase-root.MuiTab-root.Mui-selected.MuiTab-fullWidth{
  color: #af762e !important;
  font-weight: 500 !important;
}

/* .MuiButton-root:focus{
  background-color: #af762e !important;
  color:#ffffff !important
} */


.MuiInput-underline{
  bottom: none;
}

.MuiInput-underline:after {
  border-bottom: 0.5px solid #af762e !important;
  bottom: none;
}

.MuiInput-underline:hover:not(.Mui-disabled):before {
  border-bottom: 1px solid #af762e!important;
}

.MuiInput-underline:before{
  border-bottom: 0px solid rgba(0, 0, 0, 0.42);
}

.css-1qprcsu-option{
  padding:8px 9px  !important;
}

.empty-div{
  display: none;
}

.trading-footer{
  position: absolute !important;
}
.MuiFormLabel-root.Mui-focused{
  color: #af762e !important;;
}
body{
  background-color: #ffffff !important;
}
.desktopResponsive{
  display: block;
}
.mobileResponsives{
  display: none;
}
/* style added by yogitha for footer mobile responsive oj 18 june 2021 */
@media only screen and (min-width: 320px) and (max-width: 800px) {
  /* .div-footer{
    width: 100%;
    position: relative;
    bottom: 0;
  } */
  .empty-div{
    display: block !important;
  
  }
  .desktopResponsive{
    display: none;
  }
  .mobileResponsives{
    display: block;
  }
}
/* .MuiInputBase-root{
  color: gray!important;
} */
